import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useSelector
} from "react-redux";

import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";
import { SlChemistry } from "react-icons/sl";

import * as t from "../lib/types";
import {
    CONTEXT_TYPES,
    EXTRACT_CONFIRMATION_STATUS,
    EXTRACT_JOB_STATUS,
    USER_ROLES
} from "../lib/consts";
import {
    ExtractConfirmationStatus,
    IPreprocessParams,
} from "../lib/backend/extractions.types.generated";
import { Backend, BackendObj } from "../lib/backend";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectUser
} from "../lib/scraper.slice";
import {
    classNames,
    flattenScrapeDocuments,
    prettySmartDateTime,
    setDocumentTitle,
} from "../lib/utils";

import { Button, ButtonGroup, GroupButtonProps } from "../components/Button";
import { ConfirmModal } from "../components/ConfirmModal";
import { LongText } from "../components/LongText";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ViewItemTables } from "../components/ItemTables";
import { PdfViewerHorizontal } from "../components/PdfViewer";
import { ITab, CompactTabs } from "../components/Tabs";
import { ExcelViewer } from "../components/ExcelViewer";
import { SupportLink } from "../components/SupportLink";
import { TbTable } from "react-icons/tb";
import { EditExampleModal } from "../components/ExampleModals";
import { Pill } from "../components/Pill";
import { Dropdown } from "../components/Dropdown";

export function EmptyItemList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <SlChemistry className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No items</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new extraction.</p>
                <div className="mt-6">
                    <Button icon={hi.PlusIcon} text="New Extraction" href="/extraction/new" />
                </div>
            </div>
        </div>
    </div >;
}

type ItemListProps = {
    items: t.IItemSlim[];
    selected_item?: t.IItemSlim;
    onItemSelected: (item: t.IItemSlim) => void;
    is_wide: boolean;
};

export function ItemList(props: ItemListProps) {
    const { items, selected_item, onItemSelected, is_wide } = props;

    const selected_item_uuid = selected_item?.uuid;

    const getStatus = (item: t.IItemSlim): ExtractConfirmationStatus => {
        return (item.extract_confirmations_status || EXTRACT_CONFIRMATION_STATUS.none) as ExtractConfirmationStatus;
    }

    return <Fragment>
        <div className="p-2">
            <ul className="divide-y divide-gray-200">
                {items.map((item) => (
                    <li key={item.uuid} className={classNames(
                        "px-3 py-4 hover:bg-sky-100 cursor-pointer",
                        selected_item_uuid === item.uuid ? "bg-gray-200" : "",
                        is_wide ? "py-5" : "")}
                        onClick={() => onItemSelected(item)}>

                        {!is_wide && <div className="flex flex-row items-center pb-1">
                            <div className={classNames("flex-grow font-semibold text-sm truncate pl-2 border-l-4",
                                getStatus(item) === EXTRACT_CONFIRMATION_STATUS.pending ?
                                    "border-yellow-400" :
                                    getStatus(item) === EXTRACT_CONFIRMATION_STATUS.confirmed ?
                                        "border-green-400" :
                                        getStatus(item) === EXTRACT_CONFIRMATION_STATUS.rejected ?
                                            "border-red-400" :
                                            "border-gray-400")}>
                                {item.name.length > 0 ? item.name : "[no subject]"}
                            </div>
                            <span className="ml-4 text-xs text-gray-400 whitespace-nowrap">
                                {prettySmartDateTime(item.created_at)}
                            </span>
                        </div>}
                        {!is_wide && <div className="flex flex-row items-center">
                            <div className="pt-2 flex-grow truncate text-sm text-gray-600">
                                {item.template_name.length > 0 ? item.template_name : ""}
                            </div>
                        </div>}

                        {is_wide && <div className="flex flex-row items-center py-1">
                            <div className={classNames("font-semibold text-sm truncate pl-2 border-l-4",
                                getStatus(item) === EXTRACT_CONFIRMATION_STATUS.pending ?
                                    "border-yellow-400" :
                                    getStatus(item) === EXTRACT_CONFIRMATION_STATUS.confirmed ?
                                        "border-green-400" :
                                        getStatus(item) === EXTRACT_CONFIRMATION_STATUS.rejected ?
                                            "border-red-400" :
                                            "border-gray-400")}>
                                {item.name.length > 0 ? item.name : "[no subject]"}
                            </div>
                            <div className="flex-grow ml-4 truncate text-sm text-gray-600">
                                {item.template_name.length > 0 ? item.template_name : ""}
                            </div>
                            <span className="ml-4 text-xs text-gray-400 whitespace-nowrap">
                                {prettySmartDateTime(item.created_at)}
                            </span>
                        </div>}
                    </li>
                ))}
            </ul>
        </div >
    </Fragment>;
}

function EmailContentHtml(props: { item: t.IItem, html: string }) {
    const { item, html } = props;

    // go over attachments and see if we have any cid: links
    let clean_html = html;
    for (const attachment of item.attachments) {
        if (attachment.cidname !== undefined) {
            const cid = `cid:${attachment.cidname}`;
            const url = `/api/attachment/get?uuid=${attachment.uuid}`;
            clean_html = clean_html.replace(new RegExp(cid, "g"), url);
        }
    }

    return <iframe
        title="email"
        srcDoc={clean_html}
        style={{ width: "100%", height: "400px" }}
        className="border bg-white mt-2"
        sandbox="allow-same-origin" // Allows the iframe content to be treated as being from the same origin
    />;
}

function EmailContent(props: { item: t.IItem }) {
    const { item } = props;

    const is_html = item.details.email_html !== undefined;

    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <div className="pb-2"><span className="font-bold">From:</span> {item.details.from_email_address}</div>
        {item.details.to_email_addresses && <div className="pb-2"><span className="font-bold">To:</span> {item.details.to_email_addresses?.join(", ")}</div>}
        {item.details.email_subject && <div className="pb-2"><span className="font-bold">Subject:</span> {item.details.email_subject}</div>}
        {/* render html from item.details.email_html in iframe */}
        {is_html && <EmailContentHtml item={item} html={item.details.email_html || ""} />}
        {/* render text from item.text */}
        {!is_html && <LongText text={item.details.email_text || ""} />}
    </div>;
}

function DocumentContent(props: { item: t.IItem }) {
    const { item } = props;

    return item.attachments.length > 0 ? <Attachment attachment={item.attachments[0]} /> :
        <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
            {item.details.document_subject && <div className="pb-2 mb-2 border-b"><span className="font-bold">Subject:</span> {item.details.document_subject}</div>}
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>;
}

function TextContent(props: { documents: t.IScrapeDocument[] }) {
    const { documents } = props;

    const onCopy = () => {
        navigator.clipboard.writeText(flattenScrapeDocuments(documents));
    }

    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <div className="flex flex-row">
            <div className="flex-grow" />
            <button
                type="button"
                className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={onCopy}
            >
                <span className="sr-only">Copy to clipboard</span>
                <hi.ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
        <LongText text={flattenScrapeDocuments(documents)} />
    </div>;
}

function InputTextContent(props: { item: t.IItem }) {
    const { item } = props;

    const [renderings, setRenderings] = useState<{ name: string, context_uuid: string, preprocess_params?: IPreprocessParams }[]>([]);
    const [selected_context_uuid, setSelectedContextUuid] = useState<string>("");

    useEffect(() => {
        const getContextName = (context_uuid: string) => {
            return item.template.contexts.find((context) => context.uuid === context_uuid)?.name || "Unknown";
        }

        const renderings: { name: string, context_uuid: string, preprocess_params?: IPreprocessParams }[] = [];
        // check if any document/page has additional renderings
        for (const document of item.documents) {
            for (const page of document.pages) {
                // first page we see collect default preprocess params
                if (renderings.length === 0) {
                    renderings.push({ name: "Default", context_uuid: "", preprocess_params: page.preprocess_params });
                }
                // then we collect all additional renderings
                if (page.additional_renderings && page.additional_renderings.length > 0) {
                    for (const rendering of page.additional_renderings) {
                        // make sure we do not have this context already
                        if (renderings.find((r) => r.context_uuid === rendering.context_uuid) === undefined) {
                            renderings.push({ name: getContextName(rendering.context_uuid), context_uuid: rendering.context_uuid, preprocess_params: rendering.preprocess_params });
                        }
                    }
                }
            }
        }

        setRenderings(renderings);
        setSelectedContextUuid("");
    }, [item]);

    const onCopy = () => {
        navigator.clipboard.writeText(flattenScrapeDocuments(item.documents));
    }

    const render_docs = item.documents.map((document) => ({
        name: document.name,
        pages: document.pages.map((page) => ({
            name: page.name,
            preprocess_params: page.additional_renderings?.find((r) => r.context_uuid === selected_context_uuid)?.preprocess_params ?? page.preprocess_params,
            text: page.additional_renderings?.find((r) => r.context_uuid === selected_context_uuid)?.text ?? page.text
        }))
    }));

    // we have email, render it as such
    return <div className="px-2 py-4 mx-2 border border-gray-200 rounded shadow bg-gray-50">
        <div className="px-2 flex flex-row items-center gap-x-2">
            <div className="text-sm text-gray-400">Rendering:</div>
            <div className="max-w-sm w-full">
                <Dropdown
                    values={renderings.map((r) => r.name)}
                    ids={renderings.map((r) => r.context_uuid)}
                    selected={selected_context_uuid}
                    onChange={(context_uuid) => setSelectedContextUuid(context_uuid)}
                />
            </div>
            <div className="flex-grow" />
            <button
                type="button"
                className="mr-4 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={onCopy}
            >
                <span className="sr-only">Copy to clipboard</span>
                <hi.ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>

        {render_docs.map((document, i) => (
            <div key={i}>
                <h3 className="text-sm p-2 text-gray-600">Document {i + 1}: {document.name}</h3>
                {document.pages.map((page, j) => (
                    <div key={j}>
                        <h4 className="text-xs p-2 text-gray-600 flex flex-row gap-2">
                            <span className="font-semibold">{page.name}</span>
                            {page.preprocess_params !== undefined && <Fragment>
                                <span>(</span>
                                <div className="flex flex-row gap-1">
                                    {Object.keys(page.preprocess_params).map((key, idx) => <Fragment key={key}>
                                        <span className="font-mono text-xs text-gray-400">{(page.preprocess_params as any)[key]}</span>
                                        {idx < Object.keys(page.preprocess_params ?? {}).length - 1 && <span className="font-mono text-xs text-gray-400">|</span>}
                                    </Fragment>)}
                                </div>
                                <span>)</span>
                            </Fragment>}
                        </h4>
                        <div className="font-mono text-xs border bg-white">
                            <LongText text={page.text} />
                        </div>
                    </div>
                ))}
            </div>
        ))}
    </div>;
}

function Attachment(props: { attachment: t.IItemAttachmentBase }) {
    const { attachment } = props;

    const url = `/api/attachment/get?uuid=${attachment.uuid}`;
    const link = <a
        className="pl-2 pt-2 text-xs flex truncate text-sky-600 cursor-pointer hover:underline"
        href={url} target="_blank" rel="noreferrer">
        [download]
    </a>

    const lc_filename = attachment.filename.toLowerCase();

    if (
        attachment.mimetype.startsWith("text/") ||
        lc_filename.endsWith(".txt")
    ) {
        return <TextContent documents={[attachment.document]} />;
    }

    if (
        attachment.mimetype.startsWith("image/") ||
        lc_filename.endsWith(".jpg") ||
        lc_filename.endsWith(".jpeg") ||
        lc_filename.endsWith(".png") ||
        lc_filename.endsWith(".tiff") ||
        lc_filename.endsWith(".bmp") ||
        lc_filename.endsWith(".gif")
    ) {
        return <div className="mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <img src={url} alt={attachment.filename} />
            {link}
        </div>;
    }

    if (attachment.mimetype === "application/pdf" || lc_filename.endsWith(".pdf")) {
        return <div className="mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <PdfViewerHorizontal display_name={attachment.filename} file={url} max_width={300} />
            {link}
        </div>;
    }

    if (lc_filename.endsWith(".xlsx") || lc_filename.endsWith(".xls") || lc_filename.endsWith(".ods")) {
        return <div className="mx-2 px-4 py-4 border border-gray-200 rounded shadow bg-gray-50">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <ExcelViewer attachment_uuid={attachment.uuid} />
            {link}
        </div>;
    }

    const extension = attachment.filename.split(".").pop();
    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
        <div className="pb-2" >Attachment of type <span className="font-semibold font-mono">{extension}</span> is not supported for extraction and cannot be displayed.</div>
        {link}
    </div>;
};

type ItemDetailProps = {
    item?: t.IItem;
    item_job_status?: string;
    statusRefresh?: () => void;
};

export function ItemDetail(props: ItemDetailProps) {
    const { item, item_job_status, statusRefresh } = props;

    const user = useSelector(selectUser);

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selected_tab, setSelectedTab] = useState<string>("");

    useEffect(() => {
        if (item === undefined) { return; }
        const tabs: ITab[] = [];
        if (item.details.input_type === "email") {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.input_type === "documents") {
            if (item.attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.from_email_address !== undefined) {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.document_subject !== undefined || item.details.document_filename !== undefined) {
            if (item.attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else {
            tabs.push({ name: "Text", key: "text" });
        }
        for (const attachment of item.attachments) {
            tabs.push({ name: attachment.filename, key: attachment.uuid });
        }
        if (user.role === USER_ROLES.admin) {
            tabs.push({ name: "Input Text", key: "input_text" });
        }
        setTabs(tabs);
        if (tabs.length > 0) {
            setSelectedTab(tabs[0].key);
        }
    }, [user, item]);

    if (item === undefined) {
        return <div></div>;
    }

    const scrapes = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts[i];
        scrapes.push({ scrape, context });
    }

    const is_not_pending_or_rejected = item.extract_confirmations_status !== EXTRACT_CONFIRMATION_STATUS.pending &&
        item.extract_confirmations_status !== EXTRACT_CONFIRMATION_STATUS.rejected;

    return <Fragment>
        <div className="pl-2 pr-2">
            <dl className="">
                {item_job_status === EXTRACT_JOB_STATUS.running && <div className="mt-4 p-4 border bg-orange-100 rounded text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is still being processed. Please wait for the extraction to finish and refresh.</div>
                        <div className="flex-grow"></div>
                        {statusRefresh && <hi.ArrowPathIcon className="h-5 w-5 cursor-pointer" onClick={statusRefresh} />}
                    </div>
                </div>}
                {item_job_status === EXTRACT_JOB_STATUS.error && <div className="mt-4 p-4 border bg-red-50 rounded text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>There was an error processing the item.</p>
                        <p>You can see the error message in the extractions log on the <Link to="/user" className="text-sky-500 cursor-pointer hover:underline">profile</Link> page.</p>
                        <p>Please try again or contact <SupportLink />.</p>
                    </div>
                </div>}
                {item.details.retry_new_item_uuids !== undefined && item.details.retry_new_item_uuids.length > 0 && <div className="mt-4 p-4 border bg-red-100 rounded text-gray-900">
                    {item.details.retry_new_item_uuids.length === 1 && <p>
                        There is a <Link to={`/items/${item.details.retry_new_item_uuids[0]}`} className="text-sky-600 underline">new version</Link> of this extraction.
                    </p>}
                    {item.details.retry_new_item_uuids.length > 1 && <p>
                        There are {item.details.retry_new_item_uuids.length} new versions of this extraction: {item.details.retry_new_item_uuids.map((uuid, idx) =>
                            <Fragment key={idx}>{idx > 0 && ", "}<Link to={`/items/${uuid}`} className="text-sky-600 underline">version {idx + 1}</Link></Fragment>)}
                    </p>}
                </div>}
                {item.extract_confirmations_status === EXTRACT_CONFIRMATION_STATUS.pending && <div className="mt-4 p-4 border bg-yellow-100 rounded text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is pending confirmation. Please validate and confirm the extractions.</div>
                        <div className="flex-grow"></div>
                        <Button text="Go to Confirmation" highlight={true} href={`/confirm/${item.uuid}`} />
                    </div>
                </div>}
                {item.extract_confirmations_status === EXTRACT_CONFIRMATION_STATUS.rejected && <div className="mt-4 p-4 border bg-red-50 rounded text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item was rejected during confirmation and was not sent forward for processing.</div>
                    </div>
                </div>}
                {is_not_pending_or_rejected && item.digest !== undefined && !item.digest.sent && <div className="mt-4 p-4 border bg-yellow-50 rounded text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is waiting to be sent in a daily digest.</div>
                    </div>
                </div>}
                {is_not_pending_or_rejected && item.digest !== undefined && item.digest.sent && <div className="mt-4 p-4 border bg-green-50 rounded text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item was already sent in a daily digest.</div>
                    </div>
                </div>}
                {item.details.is_input_text_truncated && <div className="mt-4 p-4 border bg-gray-100 rounded text-gray-900">
                    <div className="flex flex-row items-center gap-x-2 text-sm">
                        <div><hi.ExclamationTriangleIcon className="h-5 w-5 mr-2 text-red-400" /></div>
                        <div className="flex flex-col gap-y-2">
                            <p>Input text was truncated due to its length.</p>
                            {user.role === "free" && <p>Please <a href="https://meetings-eu1.hubspot.com/tomaz/book-a-demo" className="text-sky-600" target="_blank" rel="noreferrer">contact our team</a> for more information on how to process longer documents.</p>}
                            {user.role !== "free" && <p>You can process longer document by creating an <Link to="/endpoint/new" className="text-sky-600">Integration</Link> for this template</p>}
                        </div>
                    </div>
                </div>}

                <div className="px-2 pt-4 text-sm text-gray-400 flex flex-row">
                    {item.endpoint !== undefined && <Link to={`/endpoint/${item.endpoint?.uuid}`}>
                        <div className="flex flex-row items-center hover:underline pr-2">
                            <hi.PuzzlePieceIcon className="h-4 w-4" />
                            <div className="text-gray-400 text-sm px-2">{item.endpoint?.name}</div>
                            <div>|</div>
                        </div>
                    </Link>}
                    <Link to={`/template/${item.template_uuid}`}>
                        <div className="flex flex-row items-center hover:underline">
                            <TbTable className="h-4 w-4" />
                            <div className="text-gray-400 text-sm px-2">{item.template.name}</div>
                            <div><hi.ChevronRightIcon className="h-4 w-4" /></div>
                        </div>
                    </Link>
                </div>
                <div className="px-4 py-6 sm:px-0">
                    <ViewItemTables item={item} />
                </div>
                <div className="px-4 py-6 sm:px-0">
                    <CompactTabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
                </div>
                <div className="px-4 pb-6 sm:px-0">
                    {selected_tab === "email" && <EmailContent item={item} />}
                    {selected_tab === "doc" && <DocumentContent item={item} />}
                    {item.attachments.map(a => a.uuid).includes(selected_tab) && <Attachment attachment={item.attachments.find(a => a.uuid === selected_tab)!} />}
                    {selected_tab === "text" && <TextContent documents={item.documents} />}
                    {selected_tab === "input_text" && <InputTextContent item={item} />}
                </div>
            </dl>
        </div >
    </Fragment >;
}

type ItemDownloadButtonsProps = {
    item?: t.IItem;
}

export function ItemDownloadButtons(props: ItemDownloadButtonsProps) {
    const { item } = props;

    const user = useSelector(selectUser);

    const [is_cloned, setIsCloned] = useState(false);
    const [is_new_example_modal_open, setIsNewExampleModalOpen] = useState<boolean>(false);
    const [is_being_cloned, setIsBeingCloned] = useState(false);

    useEffect(() => {
        setIsCloned(item?.details.clone_example_item_uuid !== undefined);
    }, [item]);

    const startCreateExample = () => {
        setIsNewExampleModalOpen(true);
    }

    const createExample = async (comment: string) => {
        setIsNewExampleModalOpen(false);
        if (item === undefined) { return; }
        setIsBeingCloned(true);
        try {
            await BackendObj.extractions.createExampleFromItem({ item_uuid: item?.uuid, comment })
        } catch (err) {
            console.error(err);
        }
        setIsCloned(true);
        setIsBeingCloned(false);
    }

    const downloadEvalBundle = () => {
        if (item === undefined) {
            return;
        }
        // create bundle
        const bundle: t.IEvalBundle = {
            template: {
                template_name: item.template.name,
            },
            contexts: [],
            texts: [
                {
                    name: item.name,
                    text: flattenScrapeDocuments(item.documents)
                }
            ],
            prompts: [{ prompt_name: "scrape.default", prompts: null }],
            models: [{ model_name: "model.default", params: null }],
        };
        // add contexts
        for (const context of item.template.contexts) {
            bundle.contexts.push({
                context_name: `ORG: ${context.name}`,
                facts: context.facts,
                fields: context.fields,
                extract_params: context.extract_params,
                type: context.type
            });
            bundle.contexts.push({
                context_name: `FIX: ${context.name}`,
                facts: context.facts,
                fields: context.fields,
                extract_params: context.extract_params,
                type: context.type
            });
        }
        // save json
        const dataStr = JSON.stringify(bundle, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataUri);
        downloadAnchorNode.setAttribute("download", `item_eval_bundle_${item.uuid}.json`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    if (item === undefined) {
        return <Fragment></Fragment>;
    }

    const is_admin = user.role === USER_ROLES.admin;
    const is_item_hierarchical = item?.template.contexts.some(context => context.type === CONTEXT_TYPES.hierarchical);

    const download_buttons: GroupButtonProps[] = [];
    if (is_admin) {
        download_buttons.push({ text: "Eval", onClick: downloadEvalBundle });
    }
    download_buttons.push({ text: "Excel", href: `/api/item/excel?item_uuid=${item?.uuid}`, open_in_new_tab: true });
    download_buttons.push({ text: "PDF", href: `/api/item/pdf?item_uuid=${item?.uuid}`, open_in_new_tab: true });
    if (!is_item_hierarchical) {
        download_buttons.push({ text: "JSON", href: `/api/item/json?item_uuid=${item?.uuid}`, open_in_new_tab: true });
    }
    if (item?.endpoint?.details.handlebar_template !== undefined && item?.endpoint?.details.handlebar_template !== "") {
        download_buttons.push({ text: "Custom", href: `/api/item/custom?item_uuid=${item?.uuid}`, open_in_new_tab: true });
    }

    return <Fragment>
        {is_cloned && <Pill text="Example" type="default" />}
        {!is_cloned && <Button icon={is_being_cloned ? undefined : hi.PlusCircleIcon} text="Examples" disabled={is_being_cloned} loading={is_being_cloned} onClick={startCreateExample} />}
        {download_buttons && <ButtonGroup buttons={download_buttons} disabled={!item} />}

        <EditExampleModal
            type="add"
            open={is_new_example_modal_open}
            init_comment=""
            onUpdateExample={createExample}
            onClose={() => setIsNewExampleModalOpen(false)} />
    </Fragment>
}

export function Items() {
    const navigate = useNavigate();
    const { selected_item_uuid } = useParams<{ selected_item_uuid: string | undefined }>();

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [show_items, setShowItems] = useState(true);
    const [items, setItems] = useState<t.IItemSlim[] | undefined>(undefined);
    const [selected_item, setSelectedItem] = useState<t.IItemSlim | undefined>(undefined);
    const [selected_item_detail, setSelectedItemDetail] = useState<t.IItem | undefined>(undefined);
    const [selected_item_job_status, setSelectedItemJobStatus] = useState<string | undefined>(undefined);
    const [show_confirm, setShowConfirm] = useState(false);
    const [allow_remove, setAllowRemove] = useState(true);
    const [message, setMessage] = useState<string | undefined>(undefined);

    const onItemSelected = (item: t.IItemSlim) => {
        navigate(`/items/${item.uuid}`);
    }

    useEffect(() => { setDocumentTitle("Extractions", env); }, [env]);

    // load items on first render
    useEffect(() => {
        Backend.getItems({
            limit: 100,
            offset: 0,
        }).then((items) => {
            setItems(items);
        });
    }, []);

    useEffect(() => {
        if (items === undefined) { return; }
        const item_uuid = selected_item_uuid || items[0]?.uuid || undefined;
        setSelectedItemJobStatus(undefined);
        setMessage(undefined);
        if (item_uuid !== undefined) {
            setSelectedItem(items.find((item) => item.uuid === item_uuid));
            Backend.getItem({ item_uuid }).then(({ item, message }) => {
                setSelectedItemDetail(item);
                setMessage(message);
                setSelectedItemJobStatus(undefined);
                if (item?.details.extract_job_uuid !== undefined) {
                    const job_uuid = item.details.extract_job_uuid;
                    // loop for checking status of selected item
                    Backend.getExtractJobStatus({ job_uuid }).then((status) => {
                        // update status
                        setSelectedItemJobStatus(status);
                    });
                }
            });
        }
    }, [items, selected_item_uuid]);

    useEffect(() => {
        if (selected_item === undefined) {
            setDocumentTitle("Extractions", env);
        } else {
            setDocumentTitle(`Extractions - ${selected_item.name.length > 0 ? selected_item.name : "[no subject]"}`, env);
        }
    }, [selected_item, env]);

    // remove item and refresh item list
    const onRemoveItem = async (item_uuid: string) => {
        setAllowRemove(false);
        // remember index of selected scrape, so we can select the one next to it after deletion
        const item_idx = items ? items.findIndex((item) => item.uuid === item_uuid) : 0;
        setItems(undefined);
        // delete from backend
        await Backend.deleteItem({ item_uuid });
        // retrieve new list of items
        Backend.getItems({
            limit: 100,
            offset: 0
        }).then((items) => {
            setItems(items);
            // select next item
            if (items.length > 0) {
                if (0 < item_idx && item_idx <= items.length) {
                    onItemSelected(items[item_idx - 1]);
                } else {
                    onItemSelected(items[0]);
                }
            }
        });
        setAllowRemove(true);
    };

    const onItemUpdate = async () => {
        if (selected_item === undefined) { return; }
        // get updates
        const { item } = await Backend.getItem({ item_uuid: selected_item.uuid });
        setSelectedItemDetail(item);
        const items = await Backend.getItems({ limit: 100, offset: 0 });
        setItems(items);
    };

    const refresh = () => {
        setItems(undefined);
        Backend.getItems({
            limit: 100,
            offset: 0
        }).then((items) => {
            setItems(items);
            if (items.length > 0 && selected_item === undefined) {
                onItemSelected(items[0]);
            }
        });
    };

    const statusRefresh = () => {
        setSelectedItemJobStatus(undefined);
        if (selected_item_detail === undefined) { return; }
        if (selected_item_detail.details.extract_job_uuid === undefined) { return; }
        const job_uuid = selected_item_detail.details.extract_job_uuid;
        Backend.getExtractJobStatus({ job_uuid }).then((status) => {
            setSelectedItemJobStatus(status);
            if (status !== EXTRACT_JOB_STATUS.running) {
                onItemUpdate();
            }
        });
    };


    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (selected_item !== undefined && is_remove) {
            onRemoveItem(selected_item.uuid);
        }
    };

    if (items === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (items.length === 0) {
        return <EmptyItemList />;
    }


    const is_detail_loaded = selected_item !== undefined &&
        selected_item_detail !== undefined &&
        selected_item.uuid === selected_item_detail.uuid;


    const new_extraction_uuid = selected_item_detail?.endpoint_uuid ?? selected_item_detail?.template?.uuid ?? "";

    return <Fragment>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:h-16 lg:flex lg:flex-row border-b-gray-200 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="basis-1/4">
                <div className="md:flex md:items-center md:justify-between p-4 sticky">
                    <div className="min-w-0 flex-1">
                        <Button icon={hi.PlusIcon} tooltip="New Extraction" href={`/extraction/new/${new_extraction_uuid}`} highlight={true} />
                        <Button icon={hi.ArrowPathIcon} tooltip="Refresh" onClick={refresh} />
                    </div>
                </div>
            </div>
            <div className="basis-3/4 overflow-hidden">
                <div className="md:flex md:items-center md:justify-between p-4">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                            {is_sidebar_large ? "" : "Extraction: "}
                            {selected_item ? (selected_item.name.length > 0 ? selected_item.name : "[no subject]") : "/"}
                        </h2>
                    </div>
                    <div className="flex flex-row items-center">
                        <ItemDownloadButtons item={selected_item_detail} />
                        <Button icon={hi.TrashIcon} text="Remove" onClick={() => setShowConfirm(true)} disabled={!allow_remove || !selected_item} />
                        <Button icon={SlChemistry} text="Extract" href={`/extraction/new/${new_extraction_uuid}`} highlight={true} />
                    </div>
                </div>
            </div>
        </div>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:top-16 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="basis-1/4 overflow-y-auto bg-white border-r-gray-200 border-r">
                <div className="h-auto">
                    <ItemList
                        items={items}
                        selected_item={selected_item}
                        onItemSelected={onItemSelected}
                        is_wide={false} />
                </div>
            </div>
            <div className="basis-3/4 overflow-y-auto">
                {is_detail_loaded && <ItemDetail
                    item={selected_item_detail}
                    item_job_status={selected_item_job_status}
                    statusRefresh={statusRefresh} />}
                {!is_detail_loaded && !message && <LoadingSpinner />}
                {!is_detail_loaded && message && <div className="m-4 p-4 border bg-red-50 rounded text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>{message}</p>
                    </div>
                </div>}
            </div>
        </div >
        <div className="lg:hidden w-full">
            {show_items && <ItemList
                items={items}
                selected_item={selected_item}
                onItemSelected={(item) => { onItemSelected(item); setShowItems(false); }}
                is_wide={false} />}
            {!show_items && <div className="p-4 border-t-2 border-gray-50">
                <div className="px-1">
                    <Button icon={hi.ArrowLeftIcon} text="Back" onClick={() => setShowItems(true)} />
                </div>
                {is_detail_loaded && <ItemDetail
                    item={selected_item_detail}
                    item_job_status={selected_item_job_status}
                    statusRefresh={statusRefresh} />}
                {!is_detail_loaded && !message && <LoadingSpinner />}
                {!is_detail_loaded && message && <div className="mx-2 my-4 p-4 border bg-red-50 rounded text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>{message}</p>
                    </div>
                </div>}
            </div>}
        </div>
        <ConfirmModal open={show_confirm}
            title="Remove extraction"
            message={["Are you sure you want to remove this extraction?"]}
            confirm="Remove"
            onClose={onRemoveClose} />
    </Fragment>;
}