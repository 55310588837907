import { v4 as uuidv4 } from "uuid";
import {
    unzipSync,
    Zippable,
    zipSync
} from "fflate";

import {
    DEFAULT_PREPROCESS_PARAMS,
    Env,
    FILE_EXTENSIONS_TO_MIME_TYPES,
    ONBOARDING_STEPS,
    onboarding_steps
} from "./consts";
import {
    OnboardingStep
} from "../lib/backend/extractions.types.generated";
import {
    IContextField,
    IRecordRaw,
    IScrapeDocument
} from "./types";

export async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
}

export function deepCopyTyped<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function newUuid(): string {
    return uuidv4().replace(/-/g, "");
}

export function isValidInteger(val: string): boolean {
    return Number.isInteger(Number(val));
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export function prettyNumber(val: number, digits: number): string {
    return val.toFixed(digits);
}

export function prettyDateTime(ts: number): string {
    return new Date(ts).toLocaleString();
}

export function prettyDate(ts: number): string {
    return new Date(ts).toLocaleDateString();
}

export function prettySmartDateTime(ts: number): string {
    const now = Date.now();
    const now_date = prettyDate(now);
    const date = prettyDate(ts);

    // if same day, show time
    if (now_date === date) { return prettyTime(ts); }
    // if yesterday, show "Yesterday"
    if (prettyDate(now - 24 * 3600 * 1000) === date) { return "Yesterday"; }
    // else show date
    return date;
}

export function prettyTime(ts: number): string {
    return new Date(ts).toLocaleTimeString();
}

export function prettyDuration(ts_seconds: number): string {
    const days = Math.floor(ts_seconds / 86400);
    const hours = Math.floor((ts_seconds % 86400) / 3600);
    const minutes = Math.floor((ts_seconds % 3600) / 60);
    const seconds = Math.floor(ts_seconds % 60);

    const parts = [];
    if (days > 0) { parts.push(`${days}d`); }
    if (hours > 0) { parts.push(`${hours}h`); }
    if (minutes > 0 || (days === 0 && hours === 0)) { parts.push(`${minutes}m`); }
    if (seconds > 0 || (days === 0 && hours === 0 && minutes === 0)) { parts.push(`${seconds}s`); }

    return parts.join(" ");
}

export function validateEmail(email: string): boolean {
    // https://stackoverflow.com/a/46181/1780891
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function validatePassword(password: string): boolean {
    // Regular expressions to check for lowercase, uppercase, and digit
    const has_lowercase = /[a-z]/.test(password);
    const has_uppercase = /[A-Z]/.test(password);
    const has_digit = /[0-9]/.test(password);
    const is_long_enough = password.length > 7;

    // Check if all conditions are met
    return has_lowercase && has_uppercase && has_digit && is_long_enough;
}

export function isValidIpAddress(ip: string): boolean {
    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (ipv4Regex.test(ip)) {
        return ip.split(".").every(part => parseInt(part) <= 255);
    }
    const ipv6Regex = /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i;
    if (ipv6Regex.test(ip)) {
        return ip.split(":").every(part => part.length <= 4);
    }
    if (ip.startsWith("::ffff:")) {
        return isValidIpAddress(ip.slice(7));
    }
    return false;
}

export function setDocumentTitle(title: string, env: Env) {
    const prefix = env === "prod" ? "" : `[${env.toUpperCase()}] `;

    if (title.length > 0) {
        document.title = `${prefix}Extrakt.AI - ${title}`;
    } else {
        document.title = `${prefix}Extrakt.AI`;
    }
}

/** Create safe file name from input */
export function sanitizeFileName(s: string): string {
    return s.replace(/[^a-z0-9.-]/gi, "_").toLowerCase();
}

/** This function downloads a object as a JSON file. */
export function downloadObjectAsJson(data: any, filename: string): void {
    const item_str = JSON.stringify(data, null, 2);
    const item_uri = "data:application/json;charset=utf-8," + encodeURIComponent(item_str);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", item_uri);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

// onaboarding step
export function getOnboardingStepIdx(step: OnboardingStep): number {
    return onboarding_steps.indexOf(`${step}`);
}

export function getNextOnboardingStep(step: OnboardingStep): OnboardingStep {
    const idx = getOnboardingStepIdx(step);
    // if not found, return first step
    if (idx === -1) { return ONBOARDING_STEPS.new_account; }
    // if last step, return last step
    if (idx === onboarding_steps.length - 1) { return onboarding_steps[idx] as OnboardingStep; }
    // else, return next step
    return onboarding_steps[idx + 1] as OnboardingStep;
}

/** This function performs hard-reload of the page. It also clears caches. */
export async function hardReloadPage(): Promise<void> {
    const caches_local = caches ?? window?.caches;
    if (caches_local !== undefined) {
        const cache_keys = await caches_local.keys();
        for (const cache_key of cache_keys) {
            await caches.delete(cache_key);
        }
    }
    window.location.reload();
}

/** This function redirects to external page, bypassing or more accurately, escaping React mechanism. */
export function redirectToExternalPage(url: string, new_tab?: boolean): void {
    window.open(url, new_tab ? "_blank" : "_self");
};

export function getExcelColumnName(col_num: number): string {
    let dividend = col_num + 1;
    let column_name = "";
    let modulo: number;

    while (dividend > 0) {
        modulo = (dividend - 1) % 26;
        column_name = String.fromCharCode(65 + modulo) + column_name;
        dividend = Math.floor((dividend - modulo) / 26);
    }

    return column_name;
}

export function getHierarchicalContextExample(fields: IContextField[]): { example: any } {
    // go over context fields and prepare example object with empty values
    const example: any = {};
    for (const field of fields) {
        // break field name into parts
        const parts = field.name.split("÷");
        let pointer = example;
        for (let part_n = 0; part_n < parts.length; part_n++) {
            const is_array = parts[part_n].endsWith("≤≥");
            const part = parts[part_n].replace("≤≥", "");

            if (part_n + 1 === parts.length) {
                // on the last level we set value to empty string
                pointer[part] = is_array ? [] : "";
            } else if (pointer[part] === undefined) {
                // part is not yet defined, we create an object or array
                if (is_array) {
                    pointer[part] = [{}];
                } else {
                    pointer[part] = {};
                }
            } else {
                // we already have an object, we continue with it
            }
            pointer = is_array ? pointer[part][0] : pointer[part];
        }
    }
    return { example };
}

export function getHierarchicalContextSchema(fields: IContextField[]): {
    context_schema: any, array_placeholders: { [key: string]: any }
} {
    // go over context fields and prepare placeholder object
    // in case some fields are arrays, we keep it empty array and create a separate placeholder object for each array type
    const context_schema: any = {};
    const array_placeholders: { [key: string]: any } = {};
    for (const field of fields) {
        // break field name into parts
        const parts = field.name.split("÷");
        let pointer = context_schema;
        for (let part_n = 0; part_n < parts.length; part_n++) {
            const part_path = parts.slice(0, part_n + 1).join("÷");
            const is_array = parts[part_n].endsWith("≤≥");
            const part = parts[part_n].replace("≤≥", "");

            if (part_n + 1 === parts.length) {
                // on the last level we set value to empty string
                pointer[part] = is_array ? [] : "";
                if (is_array) {
                    // this is an array of primitive values, add a placeholder for it
                    array_placeholders[part_path] = "";
                }
            } else if (pointer[part] === undefined) {
                // part is not yet defined, we create an object or array
                if (is_array) {
                    pointer[part] = [];
                    // we also create a placeholder object for prototype object of this array
                    array_placeholders[part_path] = {};
                } else {
                    pointer[part] = {};
                }
            } else {
                // we already have an object, we continue with it
            }
            pointer = is_array ? array_placeholders[part_path] : pointer[part];
        }
    }

    return { context_schema, array_placeholders };
}

// rec is field array if it ends with "≤NUMBER≥"
const is_rec_field_array = (part: string) => /≤(\d+)≥$/.test(part);
const get_rec_field_name = (part: string) => part.replace(/≤\d+≥$/, "");
const get_ref_field_idx = (part: string) => parseInt(part.match(/≤(\d+)≥$/)?.[1] || "0");

export function getHierarchicalRecord(
    flat_record_val: IRecordRaw,
    context_schema: any,
    array_placeholders: { [key: string]: any }
): { record: any } {
    const record = deepCopy(context_schema);
    for (const field in flat_record_val) {
        const parts = field.split("÷");
        let pointer = record;
        for (let part_n = 0; part_n < parts.length; part_n++) {
            const is_array = is_rec_field_array(parts[part_n]);
            const part = get_rec_field_name(parts[part_n]);
            // convert to context-field path (no array index)
            const part_path = is_array ?
                [...parts.slice(0, part_n), `${part}≤≥`].join("÷") :
                parts.slice(0, part_n + 1).join("÷");

            if (pointer[part] === undefined) {
                // unexpected field, we skip it
                break;
            } else if (part_n + 1 === parts.length) {
                if (is_array) {
                    const field_idx = get_ref_field_idx(parts[part_n]);
                    const placeholder_name = part_path.replace(/≤\d+≥/g, "≤≥");
                    const placeholder = array_placeholders[placeholder_name] || {};
                    while (pointer[part].length <= field_idx) {
                        pointer[part].push(deepCopy(placeholder));
                    }
                    pointer[part][field_idx] = flat_record_val[field];
                } else {
                    pointer[part] = flat_record_val[field];
                }
            } else if (is_array) {
                const field_idx = get_ref_field_idx(parts[part_n]);
                // remove indices from path to get placeholder name (replace ≤num≥ with ≤≥)
                const placeholder_name = part_path.replace(/≤\d+≥/g, "≤≥");
                const placeholder = array_placeholders[placeholder_name] || {};
                while (pointer[part].length <= field_idx) {
                    pointer[part].push(deepCopy(placeholder));
                }
                pointer = pointer[part][field_idx];
            } else {
                pointer = pointer[part];
            }
        }
    }

    return { record };
}

export function getTopHierarchicalFields(fields: IContextField[]): string[] {
    const { context_schema } = getHierarchicalContextSchema(fields);
    if (Object.keys(context_schema).length > 1) {
        return Object.keys(context_schema);
    } else if (Object.keys(context_schema).length === 1) {
        const top_key = Object.keys(context_schema)[0];
        return Object.keys(context_schema[top_key]);
    }
    return [];
}

export function isFlatField(field: IContextField): boolean {
    return field.name.indexOf("÷") === -1;
}

export function flattenScrapeDocuments(documents: IScrapeDocument[]): string {
    if (documents === undefined) { return ""; }
    return documents.flatMap(d => d.pages).flatMap(p => p.text).join("\n");
}

export function stringToScrapeDocuments(text: string): IScrapeDocument[] {
    return [{ type: "document", name: "", pages: [{ name: "", text, preprocess_params: DEFAULT_PREPROCESS_PARAMS }] }];
}

export function getUtcHour(local_hour: number): number {
    const offset_hours = new Date().getTimezoneOffset() / 60;
    return (local_hour + offset_hours) % 24;
}

export function getLocalHour(utc_hour: number): number {
    const offset_hours = new Date().getTimezoneOffset() / 60;
    return Math.round(utc_hour - offset_hours) % 24;
}

export function removeFileNameExtension(filename: string): string {
    return filename.replace(/\.[^/.]+$/, "");
}

export function getFileNameExtension(filename: string): string {
    return filename.split('.').pop() || "";
}

export async function unzipFile(file: File): Promise<File[]> {
    const filename_lc = file.name.toLowerCase();
    if (filename_lc.endsWith(".zip")) {
        try {
            console.log("Loading zip file:", file.name);
            const arrayBuffer = await file.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);
            const unzipped = unzipSync(uint8Array);

            const files: File[] = [];
            for (const [name, content] of Object.entries<Uint8Array>(unzipped)) {
                console.log("Unzipping file:", name);
                if (name.endsWith("/")) { continue; }  // Skip directories
                if (name.startsWith("__MACOSX/")) { continue; }  // Skip macOS metadata files
                const file_ext = getFileNameExtension(name);
                const type = FILE_EXTENSIONS_TO_MIME_TYPES[file_ext] || "application/octet-stream";
                const blob = new Blob([content], { type });
                files.push(new File([blob], name, { type }));
            }
            return files;
        } catch (error: any) {
            console.error("Error loading or unzipping file:", error.message);
            throw error;
        }
    }
    return [file];
}

export async function downloadAndZipFiles(urls: string[]): Promise<Blob> {
    // download all urls and zip them
    const responses: Zippable = {};
    for (const url of urls) {
        const response = await fetch(url);
        // get filename from disposition header
        const content_disposition = response.headers.get("Content-Disposition");
        const filename = content_disposition?.split("filename=")[1]?.replace(/^"(.*)"$/, "$1");
        // make filename unique (does not appear in response already)
        if (filename) {
            let unique_filename = filename;
            let n = 1;
            while (responses[unique_filename]) {
                // put n at the end of filename before extension
                const ext = getFileNameExtension(filename);
                unique_filename = filename.replace(`.${ext}`, ``) + `_${n}.${ext}`;
                n++;
            }
            // get response content as Uint8Array
            const arrayBuffer = await response.arrayBuffer();
            const uint8array = new Uint8Array(arrayBuffer);
            responses[unique_filename] = uint8array;
        }
    }
    const zip_uint8array = zipSync(responses);
    return new Blob([zip_uint8array], { type: "application/zip" });
}

export function isValidCodeName(code: string): boolean {
    // must start with letter, can only contain letters, numbers, and underscores
    return /^[a-zA-Z][a-zA-Z0-9_]*$/.test(code);
}

// escape field for CSV and wrap with quotes if needed
export function escapeCsvField(field: string): string {
    if (/[,"\n]/.test(field)) {
        return `"${field.replace(/"/g, '""')}"`;
    }
    return field;
}
