import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";

import {
    CONTEXT_FACT_ENTITY,
    CONTEXT_TYPES,
    USER_ROLES
} from "../lib/consts";
import * as t from "../lib/types";
import {
    classNames,
    prettySmartDateTime,
    setDocumentTitle,
    prettyDateTime,
    downloadObjectAsJson,
    flattenScrapeDocuments
} from "../lib/utils";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    BackendObj
} from "../lib/backend";
import * as t2 from "../lib/backend/extractions.types.generated";

import {
    LabeledLoadingSpinner,
    LoadingSpinner,
    LoadingSpinnerLimit
} from "../components/LoadingSpinner";
import {
    Button,
    ButtonGroup,
    GroupButtonProps
} from "../components/Button";
import { LongText } from "../components/LongText";
import { ConfirmModal } from "../components/ConfirmModal";
import { FullScreenText } from "../components/FullScreen";
import { CopyTextbox } from "../components/CopyTextbox";
import {
    FieldsTable,
    HierarchicalFieldsTable
} from "../components/FieldsTable";
import { ITab, Tabs } from "../components/Tabs";
import { Dropdown } from "../components/Dropdown";
import { ExampleItemTables } from "../components/ItemTables";
import { ErrorMessageBar } from "../components/ErrorMessageBar";
import {
    ProcessFlowBody,
    ProcessFlowHead
} from "../components/ProcessFlow";
import { OrgPill } from "../components/OrgPill";
import { ButtonMenu } from "../components/ButtonMenu";

type HistoryRec = {
    rec: t2.IContextAuditRecord;
    diff: any;
}

function TemplateHistoryDiff(props: { rec: HistoryRec }) {
    const { rec } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const helperRenderProperty = (label: string, item: any, key: number) => <Fragment>
        {item && <div key={key}>
            {label}: <span className="text-red-500">{JSON.stringify(item?.__old)}</span> &rarr; <span className="text-green-500">{JSON.stringify(item?.__new)}</span>
        </div>}
    </Fragment>;
    const helperRenderField = (d: any, field_idx: number, key: number) => <div key={key}>
        {d[0] === "+" && <span className="text-green-500">Added field {d[1].name}</span>}
        {d[0] === "~" && <span className="text-green-500">Edited field {rec.rec.fields[field_idx] ? rec.rec.fields[field_idx].name : "?"}</span>}
        {d[0] === "-" && <span className="text-red-500">Deleted field {d[1].name}</span>}
    </div>;
    const helperRenderFact = (d: any, fact_idx: number, key: number) => <div key={key}>
        {d[0] === "+" && <span className="text-green-500">Added fact {d[1].entity}</span>}
        {d[0] === "~" && <span className="text-green-500">Edited fact {rec.rec.facts[fact_idx] ? rec.rec.facts[fact_idx].entity : "?"}</span>}
        {d[0] === "-" && <span className="text-red-500">Deleted fact {d[1].entity}</span>}
    </div>;

    let show_detailed = is_admin;

    const items: JSX.Element[] = [];
    if (rec.diff.name) {
        items.push(helperRenderProperty("Name", rec.diff.name, items.length));
    }
    if (rec.diff.email_address) {
        items.push(helperRenderProperty("Email", rec.diff.email_address, items.length));
    }
    if (rec.diff.type) {
        items.push(helperRenderProperty("Number of rows", rec.diff.type, items.length));
    }
    if (rec.diff.extract_params?.remove_duplicate_records) {
        items.push(helperRenderProperty("Remove duplicate records", rec.diff.extract_params?.remove_duplicate_records, items.length));
    }
    if (rec.diff.extract_params?.default_decimal_separator) {
        items.push(helperRenderProperty("Default decimal separator", rec.diff.extract_params?.default_decimal_separator, items.length));
    }
    if (rec.diff.extract_params?.detect_decimal_separator) {
        items.push(helperRenderProperty("Detect decimal separator", rec.diff.extract_params?.detect_decimal_separator, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.try_auto_heal) {
        items.push(helperRenderProperty("Try auto-heal", rec.diff.extract_params?.try_auto_heal, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.max_partial_responses) {
        items.push(helperRenderProperty("Max partial responses", rec.diff.extract_params?.max_partial_responses, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.prompt_output_format) {
        items.push(helperRenderProperty("Prompt output format", rec.diff.extract_params?.prompt_output_format, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.extraction_strategy) {
        items.push(helperRenderProperty("Extraction strategy", rec.diff.extract_params?.extraction_strategy, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.preprocess_excel_strategy) {
        items.push(helperRenderProperty("Preprocessing excel strategy", rec.diff.extract_params?.preprocess_excel_strategy, items.length));
    }
    if (show_detailed && rec.diff.extract_params?.preprocess_ocr_strategy) {
        items.push(helperRenderProperty("Preprocessing OCR strategy", rec.diff.extract_params?.preprocess_ocr_strategy, items.length));
    }
    if (rec.diff.facts) {
        items.push(<div key={items.length}>Facts</div>);
        rec.diff.facts.map((fact: any, idx: number) => items.push(helperRenderFact(fact, idx, items.length)));
    }
    if (rec.diff.fields) {
        items.push(<div key={items.length}>Fields</div>);
        rec.diff.fields.map((field: any, idx: number) => items.push(helperRenderField(field, idx, items.length)));
    }

    if (items.length === 0) {
        return <div className="text-sm text-gray-500">Internal changes</div>;
    }

    return <Fragment>
        {items}
    </Fragment>;
}

type TemplateHistoryProps = {
    history?: HistoryRec[];
}

function TemplateHistory(props: TemplateHistoryProps) {
    const { history } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);

    return <Fragment>
        {history === undefined && <div className=""><LoadingSpinnerLimit /></div>}
        {history !== undefined && <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Author</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Details</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">&nbsp;</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {history && history.map((item, idx) => (
                    <Fragment key={idx}>
                        <tr className="text-sm text-gray-500">
                            <td className="whitespace-nowrap px-3 py-4 ">{prettyDateTime(item.rec.audit_created_at)}</td>
                            <td className="whitespace-nowrap px-3 py-4">{item.rec.audit_created_by_username}</td>
                            <td className="px-3 py-4">
                                {idx === 0 && "Created"}
                                {idx > 0 && <TemplateHistoryDiff rec={item} />}
                            </td>
                            <td className="px-3 py-4 text-right">
                                {is_admin && <button className="text-blue-500" onClick={() => setFullScreenText(JSON.stringify(item.diff, null, 2))}>Show raw</button>}
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        </table>}
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)} />
    </Fragment>;
}

export function TemplateDetail() {
    const { template_uuid } = useParams<{ template_uuid: string | undefined }>();
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const is_admin = user.role === USER_ROLES.admin;
    const admin_org_uuids = memberships.filter((m) => (m.role === "admin" || m.role === "editor")).map((m) => m.org.uuid);

    const [template, setTemplate] = useState<t.ITemplate | undefined>(undefined);
    const [template_endpoints, setTemplateEndpoints] = useState<t.IEndpointBase[] | undefined>(undefined);
    const [template_item_count, setTemplateItemCount] = useState<number | undefined>(undefined);
    const [selected_tab, setSelectedTab] = useState<string>("details");
    const [context_details, setContextDetails] = useState<t.IContextBase | undefined>(undefined);
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);
    const [history, setHistory] = useState<HistoryRec[] | undefined>(undefined);
    const [template_examples, setTemplateExamples] = useState<t.ITemplateExample[] | undefined>(undefined);
    const [show_examples_full_screen, setShowExamplesFullScreen] = useState<number>(-1);
    const [is_cloning, setIsCloning] = useState(false);
    const [show_remove_confirm, setShowRemoveConfirm] = useState(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        // first reset all the states
        setErrorMessage(undefined);
        setTemplate(undefined);
        setTemplateEndpoints(undefined);
        setTemplateItemCount(undefined);
        // load the template
        if (template_uuid === undefined) { return; }
        BackendObj.extractions.getTemplate({ template_uuid: template_uuid })
            .then(({ template, endpoints, item_count }) => {
                setTemplate(template);
                setTemplateEndpoints(endpoints);
                setTemplateItemCount(item_count);
            })
            .catch((err) => {
                setErrorMessage(err.message);
            });
    }, [template_uuid]);

    useEffect(() => {
        // on context change, reset history
        setHistory(undefined);
        // if we have a template, set the first context as selected for showing details
        if (template !== undefined) {
            setContextDetails(template.contexts[0]);
        }
    }, [template]);

    useEffect(() => {
        if (selected_tab === "examples" && template !== undefined) {
            setTemplateExamples(undefined);
            BackendObj.extractions.getTemplateExamples({ template_uuid: template.uuid })
                .then(({ examples }) => {
                    setTemplateExamples(examples);
                }).catch((err) => {
                    console.error(err);
                });
        }
    }, [template, selected_tab]);

    useEffect(() => {
        if (selected_tab === "history" && context_details !== undefined) {
            BackendObj.extractions.getContextAuditLog({ uuid: context_details.uuid }) // TODO: fix this
                .then((res) => {
                    setHistory(res.audit_log.map((obj, idx) => ({
                        rec: obj,
                        diff: res.diffs[idx]
                    })));
                }).catch((err) => {
                    console.error(err);
                });
        }
    }, [context_details, selected_tab]);

    useEffect(() => {
        if (template === undefined) {
            setDocumentTitle("Templates", env);
        } else {
            setDocumentTitle(`Templates - ${template.name}`, env);
        }
    }, [template, env]);

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    const downloadTemplateDef = async () => {
        if (template === undefined) { return; }
        const { data: template_def } = await BackendObj.extractions.exportTemplate({ template_uuid: template.uuid });
        downloadObjectAsJson(template_def, `template_def_${template.uuid}.json`);
    };

    const cloneTemplate = async () => {
        if (template === undefined) { return; }
        // commit as new template
        setIsCloning(true);
        try {
            // create new template
            const { template_uuid } = await BackendObj.extractions.importTemplate({
                data: {
                    template: {
                        uuid: template.uuid,
                        created_at: Date.now(),
                        name: `Copy of ${template.name}`,
                        org_uuid: template.org_uuid,
                        email_address: "",
                        details: template.details,
                        contexts: template.contexts.map((context) => ({
                            uuid: context.uuid,
                            template_uuid: template.uuid,
                            created_at: Date.now(),
                            name: context.name,
                            code: context.code,
                            org_uuid: context.org_uuid,
                            facts: context.facts,
                            fields: context.fields,
                            postprocess: context.postprocess,
                            type: context.type,
                            extract_params: context.extract_params,
                            overrides: context.overrides,
                            weight_score: context.weight_score
                        }))
                    },
                    examples: template_examples ?? []
                },
                org_uuid: template.org_uuid
            });
            // navigate to new template
            navigate(`/template/${template_uuid}`);
        } catch (err) {
            console.error(err);
        }
        setIsCloning(false);
    };

    const onRemoveTemplate = async (template_uuid: string) => {
        // delete from backend
        await BackendObj.extractions.deleteTemplate({ template_uuid });
        // go back to templates list
        navigate("/templates");
    };

    const onRemoveClose = async (is_remove: boolean) => {
        setShowRemoveConfirm(false);
        if (template !== undefined && is_remove) {
            onRemoveTemplate(template.uuid);
        }
    };

    if (template === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (is_cloning) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LabeledLoadingSpinner text="Creating a copy of the template" />
        </div>;
    }

    const tabs: ITab[] = [
        { name: "Details", key: "details" },
        { name: "Examples", key: "examples" },
        { name: "History", key: "history", only_admin: true }
    ];

    const changeTab = (tab_name: string) => {
        setSelectedTab(tab_name);
    };

    const remove_message = ["Existing extractions will no longer be visible after you remove the template."];
    if (template_endpoints && template_endpoints.length > 0) {
        remove_message.push(`Removing this template will also remove all associated endpoints: ${template_endpoints.map((e) => e.name).join(", ")}`);
    }

    const is_org_admin = template && admin_org_uuids.includes(template.org_uuid);

    const edit_buttons: GroupButtonProps[] = [
        { icon: hi.ArrowDownTrayIcon, text: "", onClick: downloadTemplateDef, skip: !is_admin, tooltip: "Download Definition" },
        { icon: hi.DocumentDuplicateIcon, text: "", onClick: cloneTemplate, skip: !is_org_admin, tooltip: "Clone" },
        { icon: hi.PencilSquareIcon, text: "", href: `/template/${template?.uuid}/edit`, skip: !is_org_admin, tooltip: "Edit" },
        { icon: hi.TrashIcon, text: "", onClick: () => setShowRemoveConfirm(true), skip: !is_org_admin, tooltip: "Remove" }
    ];

    const split_by_field_data = (template.details.split_by_field ?? []).map((split) => {
        const context = template.contexts.find(c => c.uuid === split.context_uuid);
        if (context === undefined) { return undefined; }
        const field = context?.fields.find(f => f.uuid === split.field_uuid);
        const context_name = context.name.length > 0 ? context.name : `Step ${context.weight_score + 1}`;
        return `${context_name}/${field?.name}`;
    }).filter((x) => x !== undefined).join(", ");

    const template_org = memberships.find(({ org }) => org.uuid === template.org_uuid);

    const extract_options: { name: string, uuid: string, admin_only: boolean }[] = [];
    if (template_endpoints !== undefined && template_endpoints.length > 0) {
        extract_options.push(...template_endpoints.map(({ name, uuid }) => ({ name, uuid, admin_only: false })));
        extract_options.push({ name: "Template only", uuid: template.uuid, admin_only: true });
    } else if (template !== undefined) {
        extract_options.push({ name: template.name, uuid: template.uuid, admin_only: false });
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-gray-200">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={hi.ArrowLeftIcon} onClick={() => navigate("/templates")} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="flex flex-row items-center  gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {template.name}
                        {template_org && <OrgPill name={template_org.org.name} type={template_org.org.type} />}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">Process Template</h2>
                </div>
                <div className="grow"></div>
                <div className="flex items-center">
                    <Fragment>
                        <ButtonGroup buttons={edit_buttons} disabled={template === undefined || is_cloning} />
                        {extract_options.length === 0 && <Button text="Extract" href={"/extraction/new/"} highlight={true} />}
                        {extract_options.length === 1 && <Button text="Extract" href={`/extraction/new/${extract_options[0].uuid}`} highlight={true} />}
                        {extract_options.length > 1 && <ButtonMenu title="Extract" highlight={true}
                            items={extract_options.map(({ name, uuid, admin_only }) =>
                                ({ title: name, onClick: () => navigate(`/extraction/new/${uuid}`), separator: admin_only, admin_only }))
                            } />}
                    </Fragment>
                </div>
            </div>
        </div>
        <div className="mt-2 pl-8 pr-8 py-6">
            {template.contexts.map((context, idx) => (<Fragment key={idx}>
                <ProcessFlowHead label={`Step ${idx + 1}`}>
                    {context.name}
                </ProcessFlowHead>
                <ProcessFlowBody>
                    <div className="outer-div">
                        {context.type !== CONTEXT_TYPES.hierarchical && <FieldsTable fields={context.fields} prev_fields={[]} context_type={context.type} />}
                        {context.type === CONTEXT_TYPES.hierarchical && <HierarchicalFieldsTable fields={context.fields} />}
                    </div>
                </ProcessFlowBody>
            </Fragment>))}
            <ProcessFlowHead label="Done" />
        </div>
        <div className="pl-2 pr-2 max-w-5xl">
            <dl className="px-4">
                <div className="pt-6">
                    <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={changeTab} />
                </div>

                {(selected_tab === "details" && <div className="px-4 pb-6 pt-8 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-b border-gray-200">
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 col-span-3">Email connector</dt>
                    <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-7 sm:mt-0">
                        <CopyTextbox text={template.email_address} email_pretty_name={template.name} is_email={true} />
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Split by field</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-7 sm:mt-0">
                        {template.details.split_by_field === undefined && "No"}
                        {template.details.split_by_field !== undefined && split_by_field_data}
                    </dd>
                </div>)}

                {(selected_tab === "details" && show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-b border-gray-200">
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing excel strategy</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {template.details.preprocess_excel_strategy === "col_names_sparse" && "Column names (sparse)"}
                        {template.details.preprocess_excel_strategy === "col_names_dense_zero" && "Column names (dense with zero)"}
                        {template.details.preprocess_excel_strategy === "col_names_dense_empty" && "Column names (dense with empty)"}
                        {template.details.preprocess_excel_strategy === "without_col_names" && "Without column names"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing OCR strategy</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {template.details.preprocess_ocr_strategy === "simple" && "Simple"}
                        {template.details.preprocess_ocr_strategy === "fix_rotation" && "Fix rotation"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing OCR table strategy</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {template.details.preprocess_ocr_table_strategy === "plain_text_only" && "Plain text only"}
                        {template.details.preprocess_ocr_table_strategy === "markdown_only" && "Markdown only"}
                        {template.details.preprocess_ocr_table_strategy === "markdown_and_plain_text" && "Markdown and plain text"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Rotation-segment handling strategy</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {template.details.orientation_segments_strategy === "as_is" && "Keep rotation as they are"}
                        {template.details.orientation_segments_strategy === "only_main" && "Only keep the main rotations"}
                        {template.details.orientation_segments_strategy === "segment" && "Segment texts by rotation"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Scrape debug</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {(template.details.scrape_debug ?? false) ? "Yes" : "No"}
                    </dd>
                </div>)}

                {(selected_tab === "details" || selected_tab === "history") &&
                    <div className="px-4 pt-6 pb-4 flex flex-row items-center">
                        <span className="text-sm min-w-[120px]">Select Step:</span>
                        <Dropdown
                            values={template.contexts.map((c, idx) => `${idx + 1}. ${c.name}`)}
                            ids={template.contexts.map(c => c.uuid)}
                            selected={context_details?.uuid || ""}
                            onChange={(context_uuid) => setContextDetails(template.contexts.find(c => c.uuid === context_uuid))} />
                    </div>}

                {selected_tab === "details" && <Fragment>
                    {context_details && <Fragment>
                        <div className="px-4 py-6">
                            <div className="flex flex-row text-sm font-medium text-gray-900 ">
                                Static Facts: {context_details.name}
                            </div>
                            <div className="pt-2 text-sm leading-6 text-gray-700">
                                {context_details.facts.length === 0 && <i className="text-gray-400">None</i>}
                                {context_details.facts.length > 0 && <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="py-1 px-4 bg-gray-100 border border-gray-200 cursor-text hover:bg-sky-100 text-left font-normal align-top">Entity</th>
                                            <th className="py-1 px-4 bg-gray-100 border border-gray-200 cursor-text hover:bg-sky-100 text-left font-normal align-top">Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {context_details.facts.map((fact, idx) => (
                                            <tr key={idx}>
                                                <td className="py-1 px-4 border">
                                                    {fact.entity === CONTEXT_FACT_ENTITY.sender ? "Email Sender" :
                                                        fact.entity === CONTEXT_FACT_ENTITY.recipient ? "Email Recipient" :
                                                            fact.entity}
                                                </td>
                                                <td className="py-1 px-4 border">{fact.role}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}
                            </div>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Remove duplicate records</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.remove_duplicate_records ? "Yes" : "No"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Default decimal separator</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.default_decimal_separator === "." ? "Decimal dot" : "Decimal comma"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Detect decimal separator</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.detect_decimal_separator ? "Yes" : "No"}
                            </dd>
                        </div>
                        <div className="px-4 pb-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Extractions</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                {template_item_count !== undefined ? template_item_count : <i className="fas fa-spinner fa-spin" />}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-2 text-gray-900">Endpoints</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-3 sm:mt-0">
                                {template_endpoints !== undefined && template_endpoints.length > 0 &&
                                    template_endpoints.map((endpoint, idx) => (<Fragment key={idx}>{idx > 0 ? "," : ""} <Link key={idx} to={`/endpoint/${endpoint.uuid}`} className="hover:underline">{endpoint.name}</Link></Fragment>))}
                                {template_endpoints !== undefined && template_endpoints.length === 0 && "None"}
                                {template_endpoints === undefined && <i className="fas fa-spinner fa-spin" />}
                            </dd>
                        </div>

                        {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Code</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 font-mono sm:col-span-2 sm:mt-0">
                                {context_details.code}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Number of rows</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.type === CONTEXT_TYPES.array && "Many"}
                                {context_details.type === CONTEXT_TYPES.lookup_table && "Many"}
                                {context_details.type === CONTEXT_TYPES.object && "One"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Extraction strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.extraction_strategy === "standard" && "Standard"}
                                {context_details.extract_params.extraction_strategy === "prepend_header_page" && "Append header pages"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing excel strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.preprocess_excel_strategy === undefined && "Inherit from template"}
                                {context_details.extract_params.preprocess_excel_strategy === "col_names_sparse" && "Column names (sparse)"}
                                {context_details.extract_params.preprocess_excel_strategy === "col_names_dense_zero" && "Column names (dense with zero)"}
                                {context_details.extract_params.preprocess_excel_strategy === "col_names_dense_empty" && "Column names (dense with empty)"}
                                {context_details.extract_params.preprocess_excel_strategy === "without_col_names" && "Without column names"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing OCR strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.preprocess_ocr_strategy === undefined && "Inherit from template"}
                                {context_details.extract_params.preprocess_ocr_strategy === "simple" && "Simple"}
                                {context_details.extract_params.preprocess_ocr_strategy === "fix_rotation" && "Fix rotation"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Preprocessing OCR table strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.preprocess_ocr_table_strategy === undefined && "Inherit from template"}
                                {context_details.extract_params.preprocess_ocr_table_strategy === "plain_text_only" && "Plain text only"}
                                {context_details.extract_params.preprocess_ocr_table_strategy === "markdown_only" && "Markdown only"}
                                {context_details.extract_params.preprocess_ocr_table_strategy === "markdown_and_plain_text" && "Markdown and plain text"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Rotation-segment handling strategy</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.orientation_segments_strategy === undefined && "Inherit from template"}
                                {context_details.extract_params.orientation_segments_strategy === "as_is" && "Keep rotation as they are"}
                                {context_details.extract_params.orientation_segments_strategy === "only_main" && "Only keep the main rotations"}
                                {context_details.extract_params.orientation_segments_strategy === "segment" && "Segment texts by rotation"}
                            </dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Maximum partial responses</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.max_partial_responses}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Prompt output format</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.prompt_output_format}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Try auto-heal</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                                {context_details.extract_params.try_auto_heal ? "Yes" : "No"}
                            </dd>
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Extract</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_extract || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Heal</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_heal || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Summarize</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_summarize || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Focused Summarize</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.scrape_focused_summarize || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Decimal Separator</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.decimal_separator || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">OC Date Validation</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.oc_date_validation || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Default</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.default || "/"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Default fast</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.models_overrides.default_fast || "/"}</dd>
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After General Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_scrape_system ? "YES" : "NO"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After Specific Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_scrape_user ? "YES" : "NO"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After General Partial Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_partial_scrape_system ? "YES" : "NO"}</dd>
                            <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Admin After Specific Partial Scrape</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">{context_details.extract_params.admin_prompts.admin_after_partial_scrape_user ? "YES" : "NO"}</dd>
                        </div>}

                        {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                            <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={hi.ChevronDownIcon} />
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                            <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={hi.ChevronUpIcon} />
                        </div>}
                    </Fragment>}
                </Fragment>}

                {selected_tab === "examples" && <Fragment>
                    {template_examples === undefined && <div className="mt-6"><LoadingSpinnerLimit /></div>}
                    {template_examples !== undefined && template_examples.length === 0 && <div className="px-4 mt-6 text-gray-500 text-sm">No examples</div>}
                    {template_examples !== undefined && template_examples.length > 0 && <Fragment>
                        {template_examples.map((example, idx) => <div key={idx} className="sm:items-start sm:gap-4 sm:p-4 mt-5 text-gray-600 text-sm border border-gray-200 bg-gray-50 rounded-md">
                            <div className="pl-2 mb-3 flex flex-row max-w-5xl ">
                                <div className="font-semibold flex flex-row">
                                    {idx + 1}. {example.item.name}
                                    <hi.ClipboardDocumentIcon
                                        className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                        onClick={() => handleCopyToClipboard(flattenScrapeDocuments(example.item.documents))} />
                                    <hi.ArrowTopRightOnSquareIcon
                                        className="h-4 w-4 ml-2 text-gray-400 cursor-pointer"
                                        onClick={() => setShowExamplesFullScreen(idx)} />
                                </div>
                            </div>
                            {example.comment.length > 0 && <div className="pl-2 my-3 text-sm max-w-5xl "><span className="text-xs">[{prettySmartDateTime(example.item.created_at)}]</span> {example.comment}</div>}
                            <div className="p-2 mt-3 border-gray-200 border rounded shadow bg-white max-w-5xl">
                                <LongText text={flattenScrapeDocuments(example.item.documents)} line_limit={5} />
                            </div>
                            <ExampleItemTables template={template} item={example.item} />
                        </div>)}
                    </Fragment>}
                </Fragment>}

                {selected_tab === "history" && <div className="py-6">
                    <TemplateHistory history={history} />
                </div>}
            </dl>
        </div>
        {/* Overlay box showing input text */}
        {template_examples && <FullScreenText
            text={flattenScrapeDocuments(template_examples[show_examples_full_screen]?.item.documents) || ""}
            show={show_examples_full_screen >= 0}
            onClose={() => setShowExamplesFullScreen(-1)} />}
        <ConfirmModal open={show_remove_confirm}
            title="Are you sure you want to remove this template?"
            message={remove_message}
            confirm="Remove"
            onClose={onRemoveClose} />
        <ErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)} />
    </div>;
}
