import {
    useState,
    useEffect,
    Fragment
} from "react";

import {
    useParams,
    useNavigate
} from "react-router-dom";
import { useSelector } from "react-redux";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import * as hi from "@heroicons/react/24/outline";
import {
    Cog8ToothIcon,
    EnvelopeOpenIcon
} from "@heroicons/react/20/solid";
import { TbTable } from "react-icons/tb";

import * as t from "../lib/types";
import {
    EndpointReplyFileFormat,
    EndpointReplyIncludeInput,
    EndpointRequireConfirmation,
    EndpointRequireConfirmationNotifyOnPending,
    EndpointType,
    EndpointWebhookStorePayload,
    UnitOfProcessing
} from "../lib/backend/extractions.types.generated";
import {
    CONTEXT_TYPES,
    ENDPOINT_TYPE,
    ORG_ROLES,
    ORG_TYPES,
    USER_ROLES
} from "../lib/consts";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    classNames,
    getLocalHour,
    getUtcHour,
    isFlatField,
    setDocumentTitle,
    validateEmail
} from "../lib/utils";
import {
    Backend,
    BackendObj
} from "../lib/backend";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { Dropdown, } from "../components/Dropdown";
import { FullScreen } from "../components/FullScreen";
import {
    WizardButtonIcon,
    WizardLogoButtonImage
} from "../components/WizardButton";
import { Tabs } from "../components/Tabs";
import { RichDropdown } from "../components/RichDropdown";
import { NumberInput } from "../components/NumberInput";
import { EndpointOutputColumns } from "../components/EndpointOutputColumns";
import { Textbox } from "../components/Textbox";
import { Checkbox } from "../components/Checkbox";
import { EndpointOutputName } from "../components/EndpointOutputName";
import { WarningModal } from "../components/WarningModal";
import { HandlebarsDoc } from "../components/HandlebarsDoc";
import { ConfirmModal } from "../components/ConfirmModal";
import { OrgPill } from "../components/OrgPill";

function NoTemplates() {
    return <div className="hidden lg:fixed lg:left-64 lg:right-0 lg:inset-y-0 lg:flex lg:flex-row">
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <hi.FolderIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No templates</h3>
                <p className="mt-1 text-sm text-gray-500">Before you can create Email or REST API integration you need to create a template.</p>
                <div className="mt-6">
                    <Button icon={hi.FolderPlusIcon} text="Create Template" href="/template/new" />
                </div>
            </div>
        </div>
    </div >;
}

type NewEndpointProps = {
    init_endpoint_type?: EndpointType;
}

export function NewEndpoint({ init_endpoint_type }: NewEndpointProps) {
    const navigate = useNavigate();

    // get redux parameters
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);
    // get list of admin orgs, since only admin can create or edit templates
    const all_orgs = memberships.map((m) => m.org);
    const admin_orgs = memberships.filter((m) => m.role === ORG_ROLES.admin).map((m) => m.org);
    const is_admin = user.role === USER_ROLES.admin;
    // only enterprise and admin users have webhook
    const is_webhook_enabled = user.role === USER_ROLES.admin || user.role === USER_ROLES.enterprise;
    const is_custom_reply_format_enabled = user.role === USER_ROLES.admin || user.role === USER_ROLES.enterprise;

    const { endpoint_uuid } = useParams<{ endpoint_uuid: string | undefined }>();

    const is_edit = endpoint_uuid !== undefined;

    // if new endpoint, we are ready to go, else we wait for endpoint to load
    const [is_init, setIsInit] = useState<boolean>(true);
    const [selected_tab_key, setSelectedTab] = useState<string>("basic_info");
    const [is_edit_endpoint_valid, setIsEditEndpointValid] = useState<boolean | undefined>(undefined);
    const [templates, setTemplates] = useState<t.ITemplateBase[] | undefined>(undefined);
    const [org_users, setOrgUsers] = useState<t.IOrgUser[]>([]);
    const [endpoint_type, setEndpointType] = useState<EndpointType>(init_endpoint_type ?? ENDPOINT_TYPE.email);
    const [endpoint_name, setEndpointName] = useState<string>("");
    const [endpoint_user_uuid, setEndpointUserUuid] = useState<string>(user.uuid);
    const [endpoint_desc, setEndpointDesc] = useState<string>("");
    const [endpoint_store_extractions, setEndpointStoreExtractions] = useState<boolean>(true);
    const [endpoint_require_confirmation, setEndpointRequireConfirmation] = useState<EndpointRequireConfirmation>("never");
    const [endpoint_require_confirmation_users, setEndpointRequireConfirmationUsers] = useState<string[]>([""]);
    const [endpoint_require_confirmation_notify_on_pending, setEndpointRequireConfirmationNotifyOnPending] = useState<EndpointRequireConfirmationNotifyOnPending>("none");
    const [endpoint_join_object_extractions, setEndpointJoinObjectExtractions] = useState<boolean>(false);
    const [endpoint_only_process_attachments, setEndpointOnlyProcessAttachments] = useState<boolean>(true);
    const [endpoint_unit_of_processing, setEndpointUnitOfProcessing] = useState<UnitOfProcessing>("file");
    const [endpoint_reply_to_sender, setEndpointReplyToSender] = useState<boolean>(true);
    const [endpoint_reply_file_format, setEndpointReplyFileFormat] = useState<EndpointReplyFileFormat>("excel");
    const [endpoint_reply_file_extension, setEndpointReplyFileExtension] = useState<string>("");
    const [endpoint_reply_file_mimetype, setEndpointReplyFileMimetype] = useState<string>("");
    const [endpoint_reply_include_input, setEndpointReplyIncludeInput] = useState<EndpointReplyIncludeInput>("all");
    const [endpoint_webhook_url, setEndpointWebhookUrl] = useState<string>("");
    const [endpoint_webhook_api_key, setEndpointWebhookApiKey] = useState<string>("");
    const [endpoint_webhook_fixed_headers, setEndpointWebhookFixedHeaders] = useState<Record<string, string>>({});
    const [endpoint_webhook_fixed_header_edit_name, setEndpointWebhookFixedHeaderEditName] = useState<string>("");
    const [endpoint_webhook_fixed_header_edit_value, setEndpointWebhookFixedHeaderEditText] = useState<string>("");
    const [endpoint_webhook_include_input, setEndpointWebhookIncludeInput] = useState<boolean>(false);
    const [endpoint_webhook_version, setEndpointWebhookVersion] = useState<"v1" | "v2">("v2");
    const [endpoint_webhook_retry_count, setEndpointWebhookRetryCount] = useState<number>(0);
    const [endpoint_webhook_store_payload, setEndpointWebhookStorePayload] = useState<EndpointWebhookStorePayload>("none");
    const [endpoint_webhook_notify_on_fail_email_address, setEndpointWebhookNotifyOnFailEmailAddress] = useState<string>("");
    const [endpoint_webhook_notify_on_fail_email_custom, setEndpointWebhookNotifyOnFailEmailCustom] = useState<string>("");
    const [endpoint_rest_api_version, setEndpointRestApiVersion] = useState<"v1" | "v2">("v2");
    const [endpoint_rest_api_check_api_key, setEndpointRestApiCheckApiKey] = useState<boolean>(true);
    const [endpoint_forward_email_address, setEndpointForwardEmailAddress] = useState<string>("");
    const [endpoint_forward_email_custom, setEndpointForwardEmailCustom] = useState<string>("");
    const [endpoint_forward_email_frequency, setEndpointForwardEmailFrequency] = useState<"per_job" | "daily">("per_job");
    const [endpoint_forward_email_digest_hour_local, setEndpointForwardEmailDigestHourLocal] = useState<number>(getLocalHour(7));
    const [endpoint_forward_email_digest_group_field_uuid, setEndpointForwardEmailDigestGroupFieldUuid] = useState<string | undefined>(undefined);
    const [endpoint_passthrough_email_address, setEndpointPassthroughEmailAddress] = useState<string>("");
    const [endpoint_passthrough_email_custom, setEndpointPassthroughEmailCustom] = useState<string>("");
    const [endpoint_passthrough_email_subject, setEndpointPassthroughEmailSubject] = useState<t.IEndpointOutputNameElement[] | undefined>(undefined);
    const [endpoint_truncate_long_text, setEndpointTruncateLongText] = useState<boolean>(false);
    const [endpoint_handlebar_template, setEndpointHandlebarTemplate] = useState<string>("");
    const [endpoint_ip_whitelist, setEndpointIpWhitelist] = useState<string[]>([]);
    const [endpoint_template, setEndpointTemplate] = useState<t.ITemplateBase | undefined>(undefined);
    const [endpoint_contexts, setEndpointContexts] = useState<t.IContextBase[]>([]);
    const [endpoint_output_sheets, setEndpointOutputSheets] = useState<t.IEndpointOutputSheet[]>([]);
    const [endpoint_output_name, setEndpointOutputName] = useState<t.IEndpointOutputNameElement[] | undefined>(undefined);
    const [is_committing, setIsCommitting] = useState<boolean>(false);
    const [is_handlebar_help_open, setIsHandlebarHelpOpen] = useState<boolean>(false);
    const [is_custom_format_not_available_open, setIsCustomFormatNotAvailableOpen] = useState<boolean>(false);
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);
    const [handlebar_json_input, setHandlebarJsonInput] = useState<string>("");
    const [handlebar_result, setHandlebarResult] = useState<string>("");
    const [handlebar_examples, setHandlebarExamples] = useState<t.IHandlebarExample[] | undefined>(undefined);
    const [is_back_modal_open, setIsBackModalOpen] = useState<boolean>(false);

    const tabs = [
        { key: "basic_info", name: "General" },
        { key: "output_sheets", name: "Custom Template" },
        { key: "email_output", name: "Result Email" },
        { key: "webhook_output", name: "Webhook" },
        { key: "details", name: "Details" }
    ];

    useEffect(() => {
        BackendObj.extractions.listTemplates({})
            .then(({ templates }) => { setTemplates(templates); })
            .catch(() => { setTemplates([]); });
    }, []);

    useEffect(() => {
        if (endpoint_uuid === undefined) {
            setIsInit(true);
            setEndpointName("");
            setEndpointDesc("");
            setEndpointContexts([]);
        } else {
            BackendObj.extractions.getEndpoint({ endpoint_uuid })
                .then(({ endpoint }) => {
                    if (endpoint === undefined) {
                        setIsEditEndpointValid(false);
                    } else {
                        const handleEmailAddress = (email_address: string, setEmailAddress: (email: string) => void, setEmailCustom: (email: string) => void) => {
                            if (org_users.find((user) => user.email === email_address)) {
                                setEmailAddress(email_address);
                                setEmailCustom("");
                            } else if (email_address !== "") {
                                setEmailAddress("CUSTOM");
                                setEmailCustom(email_address);
                            } else {
                                setEmailAddress("");
                                setEmailCustom("");
                            }
                        }

                        setIsInit(true);
                        setEndpointType(`${endpoint.type}`);
                        setEndpointName(endpoint.name);
                        // load user only if valid
                        if (endpoint.user) { setEndpointUserUuid(endpoint.user.uuid); }
                        setEndpointDesc(endpoint.description);
                        setEndpointStoreExtractions(endpoint.details.store_extractions);
                        setEndpointRequireConfirmation(endpoint.details.require_confirmation);
                        setEndpointRequireConfirmationUsers([...endpoint.details.require_confirmation_users, ""]);
                        setEndpointRequireConfirmationNotifyOnPending(endpoint.details.require_confirmation_notify_on_pending);
                        setEndpointJoinObjectExtractions(endpoint.details.join_object_extractions);
                        setEndpointOnlyProcessAttachments(endpoint.details.only_process_attachments);
                        setEndpointUnitOfProcessing(endpoint.details.unit_of_processing);
                        setEndpointReplyToSender(endpoint.details.reply_to_sender);
                        setEndpointReplyFileFormat(endpoint.details.reply_file_format);
                        setEndpointReplyFileExtension(endpoint.details.reply_file_extension);
                        setEndpointReplyFileMimetype(endpoint.details.reply_file_mimetype);
                        setEndpointHandlebarTemplate(endpoint.details.handlebar_template);
                        setEndpointReplyIncludeInput(endpoint.details.reply_include_input);
                        handleEmailAddress(endpoint.details.forward_email_address, setEndpointForwardEmailAddress, setEndpointForwardEmailCustom);
                        handleEmailAddress(endpoint.details.passthrough_email_address, setEndpointPassthroughEmailAddress, setEndpointPassthroughEmailCustom);
                        setEndpointPassthroughEmailSubject(endpoint.details.passthrough_email_subject.length > 0 ? endpoint.details.passthrough_email_subject : undefined);
                        setEndpointForwardEmailFrequency(endpoint.details.forward_email_frequency);
                        setEndpointForwardEmailDigestHourLocal(getLocalHour(endpoint.details.forward_email_digest_hour_utc));
                        setEndpointForwardEmailDigestGroupFieldUuid(endpoint.details.forward_email_digest_group_field_uuid);
                        setEndpointWebhookUrl(endpoint.details.webhook_url);
                        setEndpointWebhookApiKey(endpoint.details.webhook_api_key ?? "");
                        setEndpointWebhookFixedHeaders(endpoint.details.webhook_fixed_headers ?? {});
                        setEndpointWebhookIncludeInput(endpoint.details.webhook_include_input);
                        setEndpointWebhookVersion(endpoint.details.webhook_version);
                        setEndpointWebhookRetryCount(endpoint.details.webhook_retry_count);
                        setEndpointWebhookStorePayload(endpoint.details.webhook_store_payload);
                        setEndpointRestApiVersion(endpoint.details.rest_api_version);
                        setEndpointRestApiCheckApiKey(endpoint.details.rest_api_check_api_key);
                        handleEmailAddress(endpoint.details.webhook_notify_on_fail_email_address, setEndpointWebhookNotifyOnFailEmailAddress, setEndpointWebhookNotifyOnFailEmailCustom);
                        setEndpointTruncateLongText(endpoint.details.truncate_long_text);
                        setEndpointIpWhitelist(endpoint.ip_whitelist);
                        setEndpointOutputSheets(endpoint.output_sheets);
                        setEndpointOutputName(endpoint.output_name.length > 0 ? endpoint.output_name : undefined);
                        setEndpointTemplate(endpoint.template);
                        setEndpointContexts(endpoint.template.contexts);
                        setIsEditEndpointValid(true);
                    }
                })
                .catch((err) => {
                    setIsEditEndpointValid(false);
                    console.log(err);
                });
        }
    }, [endpoint_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (endpoint_template === undefined) { return; }
        Backend.getOrgUsers({ org_uuid: endpoint_template.org_uuid })
            .then((users) => {
                if (users.length > 0) {
                    // always current user selected first
                    setEndpointUserUuid(user.uuid);
                    // update users
                    setOrgUsers(users);
                    // check if forward user is now in the list
                    if (endpoint_forward_email_address === "CUSTOM" && users.find((user) => user.email === endpoint_forward_email_custom)) {
                        setEndpointForwardEmailAddress(endpoint_forward_email_custom);
                        setEndpointForwardEmailCustom("");
                    }
                    // check if passthrough user is now in the list
                    if (endpoint_passthrough_email_address === "CUSTOM" && users.find((user) => user.email === endpoint_passthrough_email_custom)) {
                        setEndpointPassthroughEmailAddress(endpoint_passthrough_email_custom);
                        setEndpointPassthroughEmailCustom("");
                    }
                    // check if webhook user is now in the list
                    if (endpoint_webhook_notify_on_fail_email_address === "CUSTOM" && users.find((user) => user.email === endpoint_webhook_notify_on_fail_email_custom)) {
                        setEndpointWebhookNotifyOnFailEmailAddress(endpoint_webhook_notify_on_fail_email_custom);
                        setEndpointWebhookNotifyOnFailEmailCustom("");
                    }
                } else {
                    setOrgUsers([{ ...user, user_role: user.role, org_role: "admin" }]);
                }
            });
    }, [user, endpoint_template]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (endpoint_uuid === undefined) {
            setDocumentTitle("New Integration", env);
        } else if (is_edit_endpoint_valid) {
            setDocumentTitle(`Edit Integration - ${endpoint_name}`, env);
        }
    }, [endpoint_uuid, endpoint_name, is_edit_endpoint_valid, env]);

    useEffect(() => {
        if (endpoint_reply_file_format === "custom" && endpoint_template !== undefined) {
            BackendObj.extractions.getHandlebarExamples({ template_uuid: endpoint_template.uuid })
                .then(({ examples }) => {
                    console.log(examples);
                    setHandlebarExamples(examples);
                });
        }
    }, [endpoint_reply_file_format, endpoint_template]);

    const object_contexts: t.IContextBase[] = [];
    for (const context of endpoint_contexts || []) {
        // all object contexts are ok
        if (context.type === CONTEXT_TYPES.object) {
            object_contexts.push(context);
        }
        // hierarchical contexts with at least one flat fields are ok
        if (context.type === CONTEXT_TYPES.hierarchical && context.fields.some(isFlatField)) {
            object_contexts.push(context);
        }
    }

    const changeTemplate = async (template_uuid: string) => {
        // first check if we have selected template and current endpoint name matches template name
        const name_matches = endpoint_template !== undefined && endpoint_template.name === endpoint_name;
        // select template and update contexts
        const { template } = await BackendObj.extractions.getTemplate({ template_uuid });
        if (template === undefined) { return; }
        // if same template, nothing to do
        if (endpoint_template?.uuid === template.uuid) { return; }
        setIsInit(false);
        setEndpointTemplate(template);
        setEndpointContexts(template.contexts);
        // update name only if it was the same as template name or empty
        if (endpoint_name === "" || name_matches) { setEndpointName(template.name); }
        // reset things that have context or field uuids
        setEndpointPassthroughEmailSubject(undefined);
        setEndpointOutputSheets([]);
        setEndpointOutputName(undefined);
        setHandlebarExamples(undefined);
    }

    const onCreateEndpoint = async () => {
        if (endpoint_template === undefined) { return; }
        setIsCommitting(true);
        // require confirmation is only for store extractions
        const { endpoint_uuid: new_endpoint_uuid } = await BackendObj.extractions.createEndpoint({
            org_uuid: endpoint_template.org_uuid,
            type: endpoint_type as t.EndpointType,
            name: endpoint_name,
            user_uuid: endpoint_user_uuid,
            description: endpoint_desc,
            ip_whitelist: endpoint_ip_whitelist,
            details: {
                store_extractions: endpoint_store_extractions,
                require_confirmation: endpoint_require_confirmation,
                require_confirmation_users: endpoint_require_confirmation_users.map((email) => email.trim()).filter((email) => email !== ""),
                require_confirmation_notify_on_pending: endpoint_require_confirmation_notify_on_pending,
                join_object_extractions: endpoint_join_object_extractions,
                only_process_attachments: endpoint_only_process_attachments,
                unit_of_processing: endpoint_unit_of_processing,
                reply_to_sender: endpoint_reply_to_sender,
                reply_file_format: endpoint_reply_file_format,
                reply_file_extension: endpoint_reply_file_extension,
                reply_file_mimetype: endpoint_reply_file_mimetype,
                reply_include_input: endpoint_reply_include_input,
                forward_email_address: endpoint_forward_email_address === "CUSTOM" ? endpoint_forward_email_custom : endpoint_forward_email_address,
                forward_email_frequency: endpoint_forward_email_frequency,
                forward_email_digest_hour_utc: getUtcHour(endpoint_forward_email_digest_hour_local),
                forward_email_digest_group_field_uuid: endpoint_forward_email_digest_group_field_uuid,
                passthrough_email_address: endpoint_passthrough_email_address === "CUSTOM" ? endpoint_passthrough_email_custom : endpoint_passthrough_email_address,
                passthrough_email_subject: endpoint_passthrough_email_subject ?? [],
                webhook_url: endpoint_webhook_url,
                webhook_include_input: endpoint_webhook_include_input,
                webhook_version: endpoint_webhook_version,
                webhook_retry_count: endpoint_webhook_retry_count,
                webhook_store_payload: endpoint_webhook_store_payload,
                webhook_api_key: endpoint_webhook_api_key.length > 0 ? endpoint_webhook_api_key : undefined,
                webhook_fixed_headers: {},
                webhook_notify_on_fail_email_address: endpoint_webhook_notify_on_fail_email_address === "CUSTOM" ? endpoint_webhook_notify_on_fail_email_custom : endpoint_webhook_notify_on_fail_email_address,
                rest_api_version: endpoint_rest_api_version,
                rest_api_check_api_key: endpoint_rest_api_check_api_key,
                truncate_long_text: endpoint_truncate_long_text,
                handlebar_template: endpoint_handlebar_template
            },
            output_sheets: endpoint_output_sheets,
            output_name: endpoint_output_name ?? [],
            template_uuid: endpoint_template.uuid
        });
        if (new_endpoint_uuid !== undefined) {
            navigate(`/endpoint/${new_endpoint_uuid}`);
        }
        setIsCommitting(false);
    };

    const onUpdateEndpoint = async () => {
        if (endpoint_template === undefined) { return; }
        setIsCommitting(true);
        if (endpoint_uuid !== undefined) {
            // require confirmation is only for store extractions
            await BackendObj.extractions.updateEndpoint({
                uuid: endpoint_uuid,
                type: endpoint_type as t.EndpointType,
                name: endpoint_name,
                org_uuid: endpoint_template.org_uuid,
                user_uuid: endpoint_user_uuid,
                description: endpoint_desc,
                ip_whitelist: endpoint_ip_whitelist,
                details: {
                    store_extractions: endpoint_store_extractions,
                    require_confirmation: endpoint_require_confirmation,
                    require_confirmation_users: endpoint_require_confirmation_users.map((email) => email.trim()).filter((email) => email !== ""),
                    require_confirmation_notify_on_pending: endpoint_require_confirmation_notify_on_pending,
                    join_object_extractions: endpoint_join_object_extractions,
                    only_process_attachments: endpoint_only_process_attachments,
                    unit_of_processing: endpoint_unit_of_processing,
                    reply_to_sender: endpoint_reply_to_sender,
                    reply_file_format: endpoint_reply_file_format,
                    reply_file_extension: endpoint_reply_file_extension,
                    reply_file_mimetype: endpoint_reply_file_mimetype,
                    reply_include_input: endpoint_reply_include_input,
                    forward_email_address: endpoint_forward_email_address === "CUSTOM" ? endpoint_forward_email_custom : endpoint_forward_email_address,
                    forward_email_frequency: endpoint_forward_email_frequency,
                    forward_email_digest_hour_utc: getUtcHour(endpoint_forward_email_digest_hour_local),
                    forward_email_digest_group_field_uuid: endpoint_forward_email_digest_group_field_uuid,
                    passthrough_email_address: endpoint_passthrough_email_address === "CUSTOM" ? endpoint_passthrough_email_custom : endpoint_passthrough_email_address,
                    passthrough_email_subject: endpoint_passthrough_email_subject ?? [],
                    webhook_url: endpoint_webhook_url,
                    webhook_include_input: endpoint_webhook_include_input,
                    webhook_version: endpoint_webhook_version,
                    webhook_retry_count: endpoint_webhook_retry_count,
                    webhook_store_payload: endpoint_webhook_store_payload,
                    webhook_api_key: endpoint_webhook_api_key.length > 0 ? endpoint_webhook_api_key : undefined,
                    webhook_fixed_headers: endpoint_webhook_fixed_headers,
                    webhook_notify_on_fail_email_address: endpoint_webhook_notify_on_fail_email_address === "CUSTOM" ? endpoint_webhook_notify_on_fail_email_custom : endpoint_webhook_notify_on_fail_email_address,
                    rest_api_version: endpoint_rest_api_version,
                    rest_api_check_api_key: endpoint_rest_api_check_api_key,
                    truncate_long_text: endpoint_truncate_long_text,
                    handlebar_template: endpoint_handlebar_template
                },
                output_sheets: endpoint_output_sheets,
                output_name: endpoint_output_name ?? [],
                template_uuid: endpoint_template.uuid
            });
            navigate(`/endpoint/${endpoint_uuid}`);
        }
    };

    const can_add_custom_output_name = object_contexts.length > 0;

    const addOutputNameElement = (context_uuid: string, field_uuid: string) => {
        if (!can_add_custom_output_name) { return; }
        setIsInit(false);
        const new_element = { context_uuid, field_uuid };
        if (endpoint_output_name === undefined) {
            setEndpointOutputName([new_element]);
        } else {
            setEndpointOutputName([...endpoint_output_name, new_element]);
        }
    }

    const removeOutputNameElement = (element_idx: number) => {
        setIsInit(false);
        const new_elements = [...(endpoint_output_name || [])];
        new_elements.splice(element_idx, 1);
        setEndpointOutputName(new_elements);
    }

    const getValidContextSheetFields = (context: t.IContextBase): t.IContextField[] => {
        return context.fields
            .filter((field) =>
                context.type !== CONTEXT_TYPES.hierarchical ||
                isFlatField(field))
    }

    const getValidContextSheetColumns = (context: t.IContextBase): t.IEndpointOutputColumn[] => {
        return getValidContextSheetFields(context).map((field) => ({
            name: field.name,
            type: "field",
            field: { context_uuid: context.uuid, field_uuid: field.uuid }
        }));
    }

    const addSheet = () => {
        // check if we have at least one context
        if (endpoint_contexts.length === 0) { return; }
        setIsInit(false);
        const first_context = endpoint_contexts[0];
        // add new sheet
        const old_endpoint_output_sheets = endpoint_output_sheets || [];
        setEndpointOutputSheets([...old_endpoint_output_sheets, {
            name: first_context.name,
            context_uuid: first_context.uuid,
            columns: getValidContextSheetColumns(first_context)
        }]);
    };

    const changeSheetContext = (sheet_idx: number, context_uuid: string) => {
        // find context
        const context = endpoint_contexts?.find((context) => context.uuid === context_uuid);
        if (context === undefined) { return; }
        setIsInit(false);
        // get old context and see if old name matches the old context name
        const old_context = endpoint_contexts?.find((context) => context.uuid === endpoint_output_sheets[sheet_idx].context_uuid);
        const old_name = endpoint_output_sheets[sheet_idx]?.name;
        const is_context_name = (old_context?.name === old_name);
        // update sheet
        const new_sheets = [...(endpoint_output_sheets || [])];
        new_sheets[sheet_idx].context_uuid = context_uuid;
        new_sheets[sheet_idx].columns = getValidContextSheetColumns(context);
        // we change name only if it was the same as old context name
        if (is_context_name) { new_sheets[sheet_idx].name = context.name; }
        setEndpointOutputSheets(new_sheets);
    };

    const changeSheetName = (sheet_idx: number, name: string) => {
        setIsInit(false);
        const new_sheets = [...(endpoint_output_sheets || [])];
        new_sheets[sheet_idx].name = name;
        setEndpointOutputSheets(new_sheets);
    }

    const deleteSheet = (sheet_idx: number) => {
        setIsInit(false);
        const new_sheets = [...endpoint_output_sheets];
        new_sheets.splice(sheet_idx, 1);
        setEndpointOutputSheets(new_sheets);
    }

    const addColumn = (sheet_idx: number) => {
        // find sheet context
        if (endpoint_contexts.length === 0) { return; }
        setIsInit(false);
        const new_sheets = [...endpoint_output_sheets];
        const sheet_context = endpoint_contexts.find((context) => context.uuid === new_sheets[sheet_idx].context_uuid);
        if (sheet_context === undefined) { return; }
        // add new column and use first field as default
        const sheet_fields = getValidContextSheetFields(sheet_context);
        new_sheets[sheet_idx].columns.push({
            name: sheet_fields[0].name,
            type: "field",
            field: {
                context_uuid: sheet_context.uuid,
                field_uuid: sheet_fields[0].uuid
            }
        });
        setEndpointOutputSheets(new_sheets);
    };

    const setColumn = (sheet_idx: number, column_idx: number, column: t.IEndpointOutputColumn) => {
        setIsInit(false);
        const new_sheets = [...endpoint_output_sheets];
        new_sheets[sheet_idx].columns[column_idx] = column;
        setEndpointOutputSheets(new_sheets);
    };

    const moveColumn = (sheet_idx: number, curr_idx: number, direction: "up" | "down") => {
        setIsInit(false);
        const new_sheets = [...endpoint_output_sheets];
        const new_idx = direction === "up" ? curr_idx - 1 : curr_idx + 1;
        if (new_idx >= 0 && new_idx < new_sheets[sheet_idx].columns.length) {
            const temp = new_sheets[sheet_idx].columns[curr_idx];
            new_sheets[sheet_idx].columns[curr_idx] = new_sheets[sheet_idx].columns[new_idx];
            new_sheets[sheet_idx].columns[new_idx] = temp;
            setEndpointOutputSheets(new_sheets);
        }
    };

    const deleteColumn = (sheet_idx: number, column_idx: number) => {
        setIsInit(false);
        const new_sheets = [...endpoint_output_sheets];
        new_sheets[sheet_idx].columns.splice(column_idx, 1);
        setEndpointOutputSheets(new_sheets);
    };

    const deleteAllButFirstColumn = (sheet_idx: number) => {
        const new_sheets = [...endpoint_output_sheets];
        new_sheets[sheet_idx].columns = new_sheets[sheet_idx].columns.slice(0, 1);
        setEndpointOutputSheets(new_sheets);
    }

    const handleReplyFileFormat = (format: EndpointReplyFileFormat) => {
        setIsInit(false);
        if (format === "custom" && !is_custom_reply_format_enabled) {
            setIsCustomFormatNotAvailableOpen(true);
        } else {
            setEndpointReplyFileFormat(format);
        }
    }

    const addPassthroughSubjectElement = (context_uuid: string, field_uuid: string) => {
        setIsInit(false);
        if (!can_add_custom_output_name) { return; }
        const new_element = { context_uuid, field_uuid };
        if (endpoint_passthrough_email_subject === undefined) {
            setEndpointPassthroughEmailSubject([new_element]);
        } else {
            setEndpointPassthroughEmailSubject([...endpoint_passthrough_email_subject, new_element]);
        }
    }

    const removePassthroughSubjectElement = (element_idx: number) => {
        setIsInit(false);
        const new_elements = [...(endpoint_passthrough_email_subject || [])];
        new_elements.splice(element_idx, 1);
        setEndpointPassthroughEmailSubject(new_elements);
    }

    const handleConfirmationUserChange = (idx: number, email: string) => {
        setIsInit(false);
        let new_users = [...endpoint_require_confirmation_users];
        new_users[idx] = email;
        new_users = new_users.filter((email) => email.trim() !== "");
        new_users = [...new Set(new_users)];
        new_users.push("");
        setEndpointRequireConfirmationUsers(new_users);
    }

    const handleWebhookFixedHeaderAdd = () => {
        setIsInit(false);
        if (endpoint_webhook_fixed_header_edit_name.length === 0) { return; }
        setEndpointWebhookFixedHeaders({
            ...endpoint_webhook_fixed_headers,
            [endpoint_webhook_fixed_header_edit_name]: endpoint_webhook_fixed_header_edit_value
        });
        setEndpointWebhookFixedHeaderEditName("");
        setEndpointWebhookFixedHeaderEditText("");
    }

    const handleCheckBack = () => {
        if (is_init) {
            navigate(endpoint_uuid ? `/endpoint/${endpoint_uuid}` : "/endpoints");
        } else {
            setIsBackModalOpen(true);
        }
    }

    const handleBack = (result: boolean) => {
        if (result) {
            navigate(endpoint_uuid ? `/endpoint/${endpoint_uuid}` : "/endpoints");
        }
        setIsBackModalOpen(false);
    }

    // when we are checking there if we have contexts, show spinner
    // if undefined we are still loading
    if (templates === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    // if we open in edit mode, check that we have valid context and that it is loaded
    if (is_edit) {
        if (is_edit_endpoint_valid === undefined) {
            return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
                <LoadingSpinner />
            </div>;
        }

        if (!is_edit_endpoint_valid) {
            return <div className="px-6 py-4">
                <div className="py-10">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Invalid Integration</h2>
                    <p className="mt-3 max-w-2xl text-sm leading-6 text-gray-600">
                        Integration does not exist.
                    </p>
                </div>
            </div>;
        }
    }

    if (templates.length === 0) {
        return <NoTemplates />;
    }

    const is_forward_email_valid = endpoint_type === ENDPOINT_TYPE.rest_api ||
        endpoint_forward_email_address === "" ||
        (endpoint_forward_email_address !== "CUSTOM" ?
            validateEmail(endpoint_forward_email_address) :
            validateEmail(endpoint_forward_email_custom));
    const is_passthrough_email_valid = endpoint_type === ENDPOINT_TYPE.rest_api ||
        endpoint_passthrough_email_address === "" ||
        (endpoint_passthrough_email_address !== "CUSTOM" ?
            validateEmail(endpoint_passthrough_email_address) :
            validateEmail(endpoint_passthrough_email_custom));
    const is_webhook_fail_notify_email_valid = endpoint_webhook_notify_on_fail_email_address === "" ||
        (endpoint_webhook_notify_on_fail_email_address !== "CUSTOM" ?
            validateEmail(endpoint_webhook_notify_on_fail_email_address) :
            validateEmail(endpoint_webhook_notify_on_fail_email_custom));


    const is_invalid = endpoint_name === "" || endpoint_contexts.length === 0 || !is_forward_email_valid || !is_passthrough_email_valid;

    const is_not_admin_in_all_orgs = admin_orgs.length !== all_orgs.length;
    const admin_templates = templates.filter((template) => admin_orgs.find((org) => org.uuid === template.org_uuid));


    // prepare Save template tooltip:
    let save_tooltip: string | undefined = undefined;
    if (endpoint_name === "") {
        save_tooltip = "Please provide a name for the integration";
    } else if (endpoint_contexts.length === 0) {
        save_tooltip = "Please select at least one template";
    } else if (is_edit && !is_init) {
        save_tooltip = "Unsaved changes";
    }

    const all_fields = endpoint_contexts.flatMap((context) => context.fields);
    const endpoint_org = memberships.find(({ org }) => org.uuid === endpoint_template?.org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-gray-200">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={hi.ArrowLeftIcon} onClick={handleCheckBack} />
                <ConfirmModal
                    open={is_back_modal_open}
                    title="Unsaved changes"
                    message={["There are unsaved changes to the integration.", "Are you sure you want to go back?"]}
                    cancel="No"
                    confirm="Yes"
                    onClose={handleBack} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="flex flex-row items-center  gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {endpoint_name.length > 0 ? endpoint_name : "<no name>"}
                        {endpoint_org && <OrgPill name={endpoint_org.org.name} type={endpoint_org.org.type} />}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">
                        {is_edit ? "Edit" : "New"}
                        {endpoint_type === ENDPOINT_TYPE.email && " Email "}
                        {endpoint_type === ENDPOINT_TYPE.rest_api && " Rest API "}
                        Integration
                    </h2>
                </div>
                <div className="grow"></div>
                <div className="pl-4 flex flex-col justify-end gap-1">
                    <div className="text-right">
                        {!is_edit && <Button
                            text="Create"
                            highlight={true}
                            disabled={is_invalid || is_committing}
                            loading={is_committing}
                            onClick={onCreateEndpoint} />}
                        {is_edit && <Button
                            text="Save Changes"
                            highlight={true}
                            disabled={is_invalid || is_committing}
                            loading={is_committing}
                            onClick={onUpdateEndpoint} />}
                    </div>
                    <div className="flex items-end">
                        {is_edit && save_tooltip !== undefined && <span className="text-sm text-gray-600">{save_tooltip}</span>}
                    </div>
                </div>
            </div>
        </div>

        <div className="p-8 py-6 max-w-5xl">
            <Tabs tabs={tabs} selected_tab_key={selected_tab_key} setSelectedTab={setSelectedTab} />
        </div>

        {selected_tab_key === "basic_info" && <div className="px-10 max-w-5xl">
            <form>
                <div className="py-6 sm:grid sm:grid-cols-3 max-w-5xl sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="endpoint_name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Name
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex w-full max-w-2xl rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <input
                                id="endpoint_name"
                                name="endpoint_name"
                                type="text"
                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                value={endpoint_name}
                                onChange={(e) => { setIsInit(false); setEndpointName(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                {endpoint_type === ENDPOINT_TYPE.rest_api &&
                    <div className="sm:grid sm:grid-cols-3 max-w-5xl sm:items-start sm:gap-4 pb-6">
                        <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <p>Owner</p>
                            <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                                When endpoint is called via Rest API, owner is the user that will be used for logging the call and storing results.
                            </p>
                        </label>
                        <div className="flex gap-2 mt-2 sm:col-span-2 sm:mt-0 max-w-2xl">
                            <Dropdown
                                values={org_users.map((user) => `${user.first_name} ${user.last_name} (${user.email})`)}
                                ids={org_users.map((user) => user.uuid)}
                                selected={endpoint_user_uuid}
                                onChange={(uuid) => { setIsInit(false); setEndpointUserUuid(uuid) }} />
                        </div>
                    </div>}

                <div className="sm:grid sm:grid-cols-3 max-w-5xl sm:items-start sm:gap-4 sm:py-6 border-t border-gray-900/10">
                    <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Template</p>
                        {!is_edit && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Select template you want to include in this integration.
                        </p>}
                        {is_not_admin_in_all_orgs && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Only templates from organizations where you are an admin are available.
                        </p>}
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <RichDropdown
                            values={[
                                { id: "", name: "/", icon: TbTable },
                                ...admin_templates.map((template) => ({
                                    id: template.uuid,
                                    name: template.name,
                                    icon: TbTable,
                                    org: all_orgs.find((org) => org.uuid === template.org_uuid),
                                    ts: template.created_at
                                }))
                            ]}
                            disabled={is_edit}
                            selected={endpoint_template?.uuid ?? ""}
                            onChange={(uuid) => changeTemplate(uuid)} />
                        <div className="py-2 text-sm text-gray-600">
                            {endpoint_contexts.map((context, idx) => <Fragment key={idx}>
                                <p className="font-semibold pt-4">Sheet {idx + 1}: {context.name}</p>
                                <p className="py-2">{context.fields.map((field) => field.name).join(", ")}</p>
                            </Fragment>)}
                        </div>
                    </div>
                </div>
            </form>
        </div>}

        {selected_tab_key === "email_output" && <div className="px-10 max-w-5xl">
            <form>
                {endpoint_type === ENDPOINT_TYPE.email && <div className="flex flex-row items-start sm:items-start sm:gap-4 pb-6 border-b border-gray-900/10 py-6">
                    <div className="pt-1 sm:mt-0">
                        <input
                            id="endpoint_reply_to_sender"
                            aria-describedby="endpoint_reply_to_sender"
                            name="endpoint_reply_to_sender"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={endpoint_reply_to_sender}
                            onChange={(e) => { setIsInit(false); setEndpointReplyToSender(e.target.checked) }}
                        />
                    </div>
                    <label htmlFor="endpoint_reply_to_sender" className="block text-sm font-medium leading-6 sm:pt-1.5">
                        <p>Reply to the sender</p>
                        <p className="mt-3 text-sm leading-6 font-normal text-gray-600">
                            Email with extractions is sent back to the sender.
                        </p>
                    </label>
                </div>}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 py-6">
                    <label htmlFor="endpoint_forward_email_address" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Forward email</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Forward email with original attachments and extractions to this email address.
                        </p>
                        {!is_forward_email_valid && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-red-600">
                            Invalid email address.
                        </p>}
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex flex-col gap-y-2 w-full max-w-2xl">
                            <Dropdown
                                values={["/", ...org_users.map((user) => `${user.first_name} ${user.last_name} (${user.email})`), "Custom"]}
                                ids={["", ...org_users.map((user) => user.email), "CUSTOM"]}
                                selected={endpoint_forward_email_address}
                                onChange={(user_email) => { setIsInit(false); setEndpointForwardEmailAddress(user_email) }} />
                            {endpoint_forward_email_address === "CUSTOM" && <Textbox value={endpoint_forward_email_custom} onChange={setEndpointForwardEmailCustom} />}
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 pb-6 border-b border-gray-900/10">
                    <label htmlFor="endpoint_forward_email_frequency"
                        className={classNames("block text-sm font-medium sm:col-span-2 leading-6sm:pt-1.5",
                            endpoint_forward_email_address !== "" ? "text-gray-900" : "text-gray-400")}>
                        <p>Daily digest</p>
                        <p className={classNames("mt-3 max-w-2xl text-sm leading-6 font-normal",
                            endpoint_forward_email_address !== "" ? "text-gray-600" : "text-gray-300")}>
                            Forward one email per day containing all extractions from the previous 24 hours.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <input
                            id="endpoint_forward_email_frequency"
                            aria-describedby="endpoint_forward_email_frequency"
                            name="endpoint_forward_email_frequency"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            disabled={endpoint_forward_email_address === ""}
                            checked={endpoint_forward_email_frequency === "daily"}
                            onChange={(e) => { setIsInit(false); setEndpointForwardEmailFrequency(e.target.checked ? "daily" : "per_job"); }}
                        />
                    </div>
                    <label htmlFor="endpoint_reply_file_format"
                        className={classNames("block text-sm font-medium leading-6 sm:pt-1.5",
                            endpoint_forward_email_address !== "" && endpoint_forward_email_frequency === "daily" ? "text-gray-900" : "text-gray-400")}>
                        <p>Group by field</p>
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <Dropdown
                            values={["/", ...all_fields.map((field) => field.name)]}
                            ids={["", ...all_fields.map((field) => field.uuid)]}
                            selected={endpoint_forward_email_digest_group_field_uuid || ""}
                            disabled={endpoint_forward_email_address === "" || endpoint_forward_email_frequency === "per_job"}
                            onChange={(field_uuid) => { setIsInit(false); setEndpointForwardEmailDigestGroupFieldUuid(field_uuid === "" ? undefined : field_uuid) }} />
                    </div>
                    <label htmlFor="endpoint_forward_email_digest_hour_local"
                        className={classNames("block text-sm font-medium sm:col-span-2 leading-6 sm:pt-1.5",
                            endpoint_forward_email_address !== "" && endpoint_forward_email_frequency === "daily" ? "text-gray-900" : "text-gray-400")}>
                        <p>Hour of day</p>
                        <p className={classNames("mt-3 max-w-2xl text-sm leading-6 font-normal",
                            endpoint_forward_email_address !== "" && endpoint_forward_email_frequency === "daily" ? "text-gray-600" : "text-gray-300")}>
                            Digest email will be sent at this hour every day.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0 ">
                        <Dropdown
                            values={Array.from(Array(24).keys()).map((hour) => `${hour}:00`)}
                            ids={Array.from(Array(24).keys()).map((hour) => `${hour}`)}
                            selected={`${endpoint_forward_email_digest_hour_local}`}
                            disabled={endpoint_forward_email_address === "" || endpoint_forward_email_frequency === "per_job"}
                            onChange={(hour_local) => { setIsInit(false); setEndpointForwardEmailDigestHourLocal(parseInt(hour_local, 10)) }} />
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 pb-6 py-6 border-b border-gray-900/10">
                    <label htmlFor="endpoint_reply_file_format"
                        className="block sm:col-span-2 text-sm font-medium leading-6 sm:pt-1.5 text-gray-900">
                        <p>Email attachment format</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Format of the file with extractions included in the email.
                        </p>
                    </label><div className="mt-2 sm:mt-0 ">
                        <Dropdown
                            values={["Excel", "CSV (comma)", "CSV (semicolon)", "TSV", "PDF", "JSON", "Custom"]}
                            ids={["excel", "csv", "csv-semicolon", "tsv", "pdf", "json", "custom"]}
                            selected={endpoint_reply_file_format}
                            onChange={(format) => handleReplyFileFormat(format as EndpointReplyFileFormat)} />
                        <WarningModal
                            title="Feature not available"
                            open={is_custom_format_not_available_open}
                            onClose={() => setIsCustomFormatNotAvailableOpen(false)}>
                            <div className="flex flex-col text-sm text-gray-900 gap-y-4 py-4">
                                <p>Custom attachment format is only enabled for our enterprise customers.</p>
                                <p>Please <a href="https://meetings-eu1.hubspot.com/tomaz/book-a-demo" className="text-sky-600" target="_blank" rel="noreferrer">contact our team</a> for more information on how to upgrade.</p>
                            </div>
                        </WarningModal>

                    </div>
                    <label htmlFor="endpoint_reply_include_input" className="block text-sm font-medium sm:col-span-2 leading-6 text-gray-900 sm:pt-1.5">
                        <p>Include input with the email</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Email will include original email with attachments.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Dropdown
                            values={["No", "Attachments only", "Email and attachments"]}
                            ids={["no", "attachments", "all"]}
                            selected={endpoint_reply_include_input}
                            onChange={(include_input) => { setIsInit(false); setEndpointReplyIncludeInput(include_input as EndpointReplyIncludeInput) }} />
                    </div>
                    {endpoint_reply_file_format === "custom" && <Fragment>
                        <label htmlFor="endpoint_reply_file_extension" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <p>Reply file extension</p>
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-2xl rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <input
                                    id="endpoint_reply_file_extension"
                                    name="endpoint_reply_file_extension"
                                    type="text"
                                    className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 font-mono"
                                    value={endpoint_reply_file_extension}
                                    onChange={(e) => { setIsInit(false); setEndpointReplyFileExtension(e.target.value) }}
                                />
                            </div>
                        </div>
                    </Fragment>}
                    {endpoint_reply_file_format === "custom" && <Fragment>
                        <label htmlFor="endpoint_reply_file_mimetype" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <p>Reply file mimetype</p>
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2 ">
                            <div className="flex w-full max-w-2xl rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <input
                                    id="endpoint_reply_file_mimetype"
                                    name="endpoint_reply_file_mimetype"
                                    type="text"
                                    className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 font-mono"
                                    value={endpoint_reply_file_mimetype}
                                    onChange={(e) => { setIsInit(false); setEndpointReplyFileMimetype(e.target.value) }}
                                />
                            </div>
                        </div>
                    </Fragment>}
                    {endpoint_reply_file_format === "custom" && <div className="sm:col-span-6">
                        <div className="py-4">
                            <div className="my-2 sm:mt-0">
                                <div className="flex flex-row items-center pb-2">
                                    <span className="text-sm font-medium leading-6 text-gray-900">Use handlebar notation to define custom format</span>
                                    <span><hi.QuestionMarkCircleIcon className="text-gray-400 w-4 h-4 ml-2 cursor-pointer" onClick={() => setIsHandlebarHelpOpen(true)} /></span>
                                </div>
                                <FullScreen show={is_handlebar_help_open} onClose={() => setIsHandlebarHelpOpen(false)}>
                                    <HandlebarsDoc />
                                </FullScreen>
                                <div className="w-full shadow border">
                                    <CodeMirror
                                        value={endpoint_handlebar_template || ""}
                                        height="500px"
                                        theme="light"
                                        onChange={(value) => { setIsInit(false); setEndpointHandlebarTemplate(value) }} />
                                </div>
                                <div className="my-4 flex flex-col space-y-4">
                                    <div className="flex flex-row space-x-4">
                                        <div className="w-1/2">
                                            <span className="text-sm font-medium leading-6 text-gray-900">JSON Input</span>
                                            <div className="w-full shadow border mt-2">
                                                <CodeMirror
                                                    value={handlebar_json_input}
                                                    height="200px"
                                                    theme="light"
                                                    onChange={(value) => {
                                                        setHandlebarJsonInput(value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <span className="text-sm font-medium leading-6 text-gray-900">Handlebar Result</span>
                                            <div className="w-full shadow border mt-2">
                                                <CodeMirror
                                                    value={handlebar_result}
                                                    height="200px"
                                                    theme="light"
                                                    readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="w-64">
                                            <Dropdown
                                                values={[
                                                    "Select an example",
                                                    ...handlebar_examples?.map(example => `${example.name} - ${example.comment}`) || []
                                                ]}
                                                ids={[
                                                    "",
                                                    ...handlebar_examples?.map((_, index) => index.toString()) || []
                                                ]}
                                                selected=""
                                                onChange={(selectedIndex) => {
                                                    const index = parseInt(selectedIndex, 10);
                                                    if (!isNaN(index) && handlebar_examples && index >= 0 && index < handlebar_examples.length) {
                                                        setHandlebarJsonInput(handlebar_examples[index].json);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Button text="Test" onClick={() => {
                                            BackendObj.extractions.getHandlebarsResult(
                                                { template: endpoint_handlebar_template, data: handlebar_json_input })
                                                .then((res) => {
                                                    if (res.error !== undefined) {
                                                        setHandlebarResult(`${res.error}`);
                                                    } else {
                                                        setHandlebarResult(res.rendered_template);
                                                    }
                                                }).catch((err) => {
                                                    setHandlebarResult(`${err}`);
                                                });
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <label htmlFor="can_add_custom_output_name"
                        className={classNames("block text-sm font-medium sm:col-span-2 leading-6 sm:pt-1.5",
                            can_add_custom_output_name ? "text-gray-900" : "text-gray-400")}>
                        <p>Custom extractions filename</p>
                        {can_add_custom_output_name && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            By default filename is "extractions". You can change it here to contain the
                            values from the extractions like PO number, invoice number, etc.
                        </p>}
                        {!can_add_custom_output_name && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-300">
                            Custom extractions filename is available only when integrations has at least one non-tabular template.
                        </p>}
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Checkbox
                            id="can_add_custom_output_name"
                            aria-describedby="can_add_custom_output_name"
                            checked={endpoint_output_name !== undefined}
                            disabled={!can_add_custom_output_name}
                            setChecked={(checked) => { setIsInit(false); if (checked) { setEndpointOutputName([]); } else { setEndpointOutputName(undefined); } }}
                        />
                    </div>
                    {endpoint_output_name && <div className="mt-2 sm:mt-0 sm:col-span-3">
                        <EndpointOutputName
                            object_contexts={object_contexts}
                            elements={endpoint_output_name}
                            addElement={addOutputNameElement}
                            removeElement={removeOutputNameElement} />
                    </div>}
                </div>

                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 pb-6 py-6">
                    <label htmlFor="endpoint_passthrough_email_address" className="block text-sm font-medium sm:col-span-2 leading-6 text-gray-900 sm:pt-1.5">
                        <p>Passthrough email</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Forward incoming email to this email address with adjusted subject.
                        </p>
                        {!is_passthrough_email_valid && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-red-600">
                            Invalid email address.
                        </p>}
                    </label>
                    <div className="mt-2 sm:col-span-4 sm:mt-0">
                        <div className="flex flex-col gap-y-2 w-full max-w-2xl">
                            <Dropdown
                                values={["/", ...org_users.map((user) => `${user.first_name} ${user.last_name} (${user.email})`), "Custom"]}
                                ids={["", ...org_users.map((user) => user.email), "CUSTOM"]}
                                selected={endpoint_passthrough_email_address}
                                onChange={(user_email) => { setIsInit(false); setEndpointPassthroughEmailAddress(user_email) }} />
                            {endpoint_passthrough_email_address === "CUSTOM" && <Textbox
                                value={endpoint_passthrough_email_custom}
                                onChange={(value) => { setIsInit(false); setEndpointPassthroughEmailCustom(value) }} />}
                        </div>
                    </div>
                    <label htmlFor="can_add_custom_email_subject"
                        className={classNames("block text-sm font-medium sm:col-span-2 leading-6 sm:pt-1.5",
                            can_add_custom_output_name ? "text-gray-900" : "text-gray-400")}>
                        <p>Custom email subject</p>
                        {can_add_custom_output_name && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            By default subject is "Ex: {"<original subject>"}. You can change it here to contain the
                            values from the extractions like PO number, invoice number, etc.
                        </p>}
                        {!can_add_custom_output_name && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-300">
                            Custom extractions filename is available only when integrations has at least one non-tabular template.
                        </p>}
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Checkbox
                            id="can_add_custom_email_subject"
                            aria-describedby="can_add_custom_email_subject"
                            checked={endpoint_passthrough_email_subject !== undefined}
                            disabled={!can_add_custom_output_name}
                            setChecked={(checked) => { setIsInit(false); if (checked) { setEndpointPassthroughEmailSubject([]); } else { setEndpointPassthroughEmailSubject(undefined); } }}
                        />
                    </div>
                    {endpoint_passthrough_email_subject && <div className="mt-2 sm:mt-0 sm:col-span-3">
                        <EndpointOutputName
                            title={"Custom subject"}
                            object_contexts={object_contexts}
                            elements={endpoint_passthrough_email_subject}
                            addElement={addPassthroughSubjectElement}
                            removeElement={removePassthroughSubjectElement} />
                    </div>}

                </div>
            </form>
        </div>}

        {selected_tab_key === "output_sheets" && <div className="px-10 max-w-5xl">
            <div className="flex flex-row items-start sm:items-start sm:gap-4 py-6 max-w-3xl">
                <div className="pt-1 sm:mt-0">
                    <input
                        id="endpoint_output_sheets"
                        aria-describedby="endpoint_output_sheets"
                        name="endpoint_output_sheets"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                        disabled={endpoint_contexts.length === 0}
                        checked={endpoint_output_sheets.length > 0}
                        onChange={(e) => {
                            setIsInit(false);
                            if (e.target.checked) {
                                addSheet();
                            } else {
                                setEndpointOutputSheets([]);
                            }
                        }}
                    />
                </div>
                <label htmlFor="endpoint_output_sheets" className="block text-sm font-medium leading-6 sm:pt-1.5">
                    <p className={classNames(endpoint_contexts.length > 0 ? "text-gray-900" : "text-gray-400")}>
                        Custom result template
                    </p>
                    <p className={classNames("mt-3 text-sm leading-6 font-normal", endpoint_contexts.length > 0 ? "text-gray-600" : "text-gray-300")}>
                        By default all fields from selected templates are included in the reply or forward email.
                        If you want to customize the output, you can do it here. Just list the sheets
                        and columns you want to include in the output, like you would in Excel.
                    </p>
                </label>
            </div>

            {endpoint_output_sheets.length > 0 && <div className="max-w-3xl">
                <div className="flex flex-row items-center gap-2 px-2 pt-6">
                    <h3 className="text-sm font-semibold leading-6 text-gray-900">Output Sheets</h3>
                    <div className="flex-grow" />
                    <Button icon={hi.PlusIcon} text="Add Sheet" onClick={addSheet} />
                </div>
                {endpoint_output_sheets.map((sheet, idx) => (
                    <div key={idx} className="py-6">
                        <div className="px-2 pb-4 flex flex-row items-center gap-2">
                            <span className="text-sm font-semibold pr-4">Sheet {idx + 1}</span>
                            <div className="flex-grow" />
                            <Button icon={hi.TrashIcon} onClick={() => deleteSheet(idx)} />
                        </div>
                        <div className="px-2 pb-4 grid grid-cols-2 items-center gap-2">
                            <div>
                                <span className="text-sm pr-4">Sheet name:</span>
                                <Textbox value={sheet.name} onChange={(name) => changeSheetName(idx, name)} />
                            </div>
                            <div>
                                <span className="text-sm pr-4">Base template:</span>
                                <RichDropdown
                                    values={endpoint_contexts
                                        .map((context) => ({
                                            id: context.uuid,
                                            name: context.name,
                                            icon: TbTable
                                        }))}
                                    selected={sheet.context_uuid}
                                    onChange={(context_uuid) => changeSheetContext(idx, context_uuid)} />
                            </div>
                        </div>
                        <EndpointOutputColumns
                            context={endpoint_contexts.find((context) => context.uuid === sheet.context_uuid) as t.IContextBase}
                            object_contexts={object_contexts.filter((context) => sheet.context_uuid !== context.uuid)}
                            columns={sheet.columns}
                            show_hash_key={false}
                            addColumn={() => addColumn(idx)}
                            setColumn={(column_idx, column) => setColumn(idx, column_idx, column)}
                            moveColumn={(curr_idx, direction) => moveColumn(idx, curr_idx, direction)}
                            deleteColumn={(column_idx) => deleteColumn(idx, column_idx)}
                            deleteAllButFirstColumn={() => deleteAllButFirstColumn(idx)}
                        />
                    </div>
                ))}
            </div>}
        </div>}

        {selected_tab_key === "webhook_output" && is_webhook_enabled && <div className="px-10 max-w-5xl">
            <form>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 py-6">
                    <label htmlFor="endpoint_webhook_url" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Webhook URL</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            This is the URL where we will send the data after extraction.
                            If confirmation is required, we will send the data only after confirmation.
                        </p>
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex w-full max-w-2xl rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <input
                                id="endpoint_webhook_url"
                                name="endpoint_webhook_url"
                                type="text"
                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 font-mono"
                                value={endpoint_webhook_url}
                                onChange={(e) => { setIsInit(false); setEndpointWebhookUrl(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 py-6">
                    <label htmlFor="endpoint_webhook_url" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Webhook API key</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Optional. This key is inserted into HTTP header ("X-API-KEY") of webhook calls.
                        </p>
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex w-full max-w-2xl rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <input
                                id="endpoint_webhook_api_key"
                                name="endpoint_webhook_api_key"
                                type="text"
                                placeholder="If empty, no key is sent"
                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 font-mono"
                                value={endpoint_webhook_api_key}
                                onChange={(e) => { setIsInit(false); setEndpointWebhookApiKey(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="endpoint_require_endpoint_webhook_include_inputconfirmation" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Include input with webhook call</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Also includes attachments from emails.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <input
                            id="endpoint_webhook_include_input"
                            aria-describedby="endpoint_webhook_include_input"
                            name="endpoint_webhook_include_input"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={endpoint_webhook_include_input}
                            onChange={(e) => { setIsInit(false); setEndpointWebhookIncludeInput(e.target.checked) }}
                        />
                    </div>
                    <label className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Webhook format version</p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="w-20">
                            <Dropdown
                                values={["v1", "v2"]}
                                selected={endpoint_webhook_version}
                                onChange={(version: string) => { setIsInit(false); setEndpointWebhookVersion(version === "v1" ? "v1" : "v2") }} />
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 pb-6">
                    <label className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Retry count</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Number of times we will retry webhook call in case of failure.
                            Default is 0 (no retries). Maximum is 10.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="w-20">
                            <NumberInput
                                value={endpoint_webhook_retry_count}
                                setValue={(value) => { setIsInit(false); setEndpointWebhookRetryCount(value) }} min={0} max={10} />
                        </div>
                    </div>

                    <label htmlFor="endpoint_webhook_store_payload" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Debug logging for webhook</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Log all or only failed webhook calls and responses for 7 days. Logs are available under endpoint extract job details.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Dropdown
                            values={["None", "All", "Only Failed"]}
                            ids={["none", "all", "only_failed"]}
                            selected={endpoint_webhook_store_payload}
                            onChange={(type) => { setIsInit(false); setEndpointWebhookStorePayload(type as EndpointWebhookStorePayload) }} />
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 py-6">
                    <label htmlFor="endpoint_webhook_notify_on_fail_email_address" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Fail notify email</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Email address that will receive notifications when webhook call fails.
                        </p>
                        {!is_webhook_fail_notify_email_valid && <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-red-600">
                            Invalid email address.
                        </p>}
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex flex-col gap-y-2 w-full max-w-2xl">
                            <Dropdown
                                values={["/", ...org_users.map((user) => `${user.first_name} ${user.last_name} (${user.email})`), "Custom"]}
                                ids={["", ...org_users.map((user) => user.email), "CUSTOM"]}
                                selected={endpoint_webhook_notify_on_fail_email_address}
                                onChange={(user_email) => { setIsInit(false); setEndpointWebhookNotifyOnFailEmailAddress(user_email) }} />
                            {endpoint_webhook_notify_on_fail_email_address === "CUSTOM" && <Textbox
                                value={endpoint_webhook_notify_on_fail_email_custom}
                                onChange={(value) => { setIsInit(false); setEndpointWebhookNotifyOnFailEmailCustom(value) }} />}
                        </div>
                    </div>
                </div>


                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="endpoint_webhook_fixed_headers" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Fixed headers</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Add fixed HTTP headers to the webhook call. These headers cannot be overwritten from outside, e.g. from incoming REST API request.
                        </p>
                    </label>
                    <div className="sm:col-span-4">
                        {Object.keys(endpoint_webhook_fixed_headers).length > 0 && <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Key</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Value</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {Object.entries(endpoint_webhook_fixed_headers).map(([key, value], index) => (
                                    <tr key={index}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{key}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{value}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1">
                                            <Button icon={hi.PencilIcon} onClick={() => {
                                                setIsInit(false);
                                                setEndpointWebhookFixedHeaderEditName(key);
                                                setEndpointWebhookFixedHeaderEditText(value);
                                            }} />
                                            <Button icon={hi.TrashIcon} onClick={() => {
                                                setIsInit(false);
                                                const newHeaders = { ...endpoint_webhook_fixed_headers };
                                                delete newHeaders[key];
                                                setEndpointWebhookFixedHeaders(newHeaders);
                                            }} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                        <div className="mt-4 flex flex-row gap-x-2 items-center">
                            <Textbox
                                placeholder="HTTP header name"
                                value={endpoint_webhook_fixed_header_edit_name}
                                onChange={(value) => { setIsInit(false); setEndpointWebhookFixedHeaderEditName(value) }} />
                            <Textbox
                                placeholder="HTTP header fixed value"
                                value={endpoint_webhook_fixed_header_edit_value}
                                onChange={(value) => { setIsInit(false); setEndpointWebhookFixedHeaderEditText(value) }} />
                            <Button text="Add" onClick={handleWebhookFixedHeaderAdd} />
                        </div>
                    </div>
                </div>
            </form>
        </div>}

        {selected_tab_key === "webhook_output" && !is_webhook_enabled && <div className="px-10 max-w-5xl">
            <div className="flex flex-col text-sm gap-y-4 p-4 border bg-sky-50 rounded text-gray-900">
                <p>Webhook are only available to our enterprise customers.</p>
                <p>Please <a href="https://meetings-eu1.hubspot.com/tomaz/book-a-demo" className="text-sky-600" target="_blank" rel="noreferrer">contact our team</a> for more information on how to upgrade.</p>
            </div>
        </div>}

        {selected_tab_key === "details" && <div className="px-10 max-w-5xl">
            <form>
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 py-6 border-b border-gray-900/10">
                    <label htmlFor="endpoint_require_confirmation" className="block text-sm font-medium sm:col-span-3 leading-6 text-gray-900 sm:pt-1.5">
                        <p>Require Confirmation</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            When should we require confirmation before sending the data to the webhook or email.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Dropdown
                            values={["No", "Yes", "Only on error"]}
                            ids={["never", "always", "on_error"]}
                            selected={endpoint_require_confirmation}
                            onChange={(type) => { setIsInit(false); setEndpointRequireConfirmation(type as EndpointRequireConfirmation) }} />
                    </div>
                    {endpoint_require_confirmation !== "never" && <div className="sm:col-span-4 grid grid-cols-2 gap-4">
                        <label htmlFor="endpoint_require_confirmation_users" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <p>Confirmation users</p>
                            <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                                Users that will receive the confirmation request.<br /> Senders always receive the confirmation request.
                            </p>
                        </label>
                        <div className="flex flex-col gap-4">
                            {endpoint_require_confirmation_users.map((user_email, idx) => (
                                <Dropdown
                                    key={idx}
                                    values={["/", ...org_users.map((user) => `${user.first_name} ${user.last_name} (${user.email})`)]}
                                    ids={["", ...org_users.map((user) => user.email)]}
                                    selected={user_email}
                                    onChange={(email) => { setIsInit(false); handleConfirmationUserChange(idx, email) }} />
                            ))}
                        </div>
                    </div>}
                    <label htmlFor="endpoint_require_confirmation_notify_on_pending" className="block text-sm font-medium sm:col-span-3 leading-6 text-gray-900 sm:pt-1.5">
                        <p>Notify on pending</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Who should receive email notifications when extraction requires confirmation and is pending.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Dropdown
                            values={["None", "Confirmation users", "Sender", "All"]}
                            ids={["none", "users", "sender", "all"]}
                            selected={endpoint_require_confirmation_notify_on_pending}
                            onChange={(type) => { setIsInit(false); setEndpointRequireConfirmationNotifyOnPending(type as EndpointRequireConfirmationNotifyOnPending) }} />
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 py-6">
                    <label htmlFor="endpoint_store_extractions" className="block text-sm font-medium sm:col-span-2 leading-6 text-gray-900 sm:pt-1.5">
                        <p>Store Extractions</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Extract-and-Forget when not enabled. If enabled, incoming items and extractions will be stored in the database.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <input
                            id="endpoint_store_extractions"
                            aria-describedby="endpoint_store_extractions"
                            name="endpoint_store_extractions"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                            checked={endpoint_store_extractions}
                            onChange={(e) => { setIsInit(false); setEndpointStoreExtractions(e.target.checked) }}
                        />
                    </div>


                    <label htmlFor="endpoint_join_object_extractions" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Join extractions</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Add extractions from single-row templates to each row in multi-row templates.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <input
                            id="endpoint_join_object_extractions"
                            aria-describedby="endpoint_join_object_extractions"
                            name="endpoint_join_object_extractions"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={endpoint_join_object_extractions}
                            onChange={(e) => { setIsInit(false); setEndpointJoinObjectExtractions(e.target.checked) }}
                        />
                    </div>
                    {endpoint_type === ENDPOINT_TYPE.email && <Fragment>
                        <label htmlFor="endpoint_only_process_attachments" className="block text-sm font-medium sm:col-span-2 leading-6 text-gray-900 sm:pt-1.5">
                            <p>Only process attachments</p>
                            <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                                When email has PDF or Excel attachments, then only attachments will be processed. The email body will be ignored.
                            </p>
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <input
                                id="endpoint_only_process_attachments"
                                aria-describedby="endpoint_only_process_attachments"
                                name="endpoint_only_process_attachments"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                checked={endpoint_only_process_attachments}
                                onChange={(e) => { setIsInit(false); setEndpointOnlyProcessAttachments(e.target.checked) }}
                            />
                        </div>
                    </Fragment>}

                    <label htmlFor="endpoint_unit_of_processing"
                        className="block sm:col-span-2 text-sm font-medium leading-6 sm:pt-1.5 text-gray-900">
                        <p>Unit of processing</p>
                        <p className="mt-3 max-w-2xl text-sm leading-6 font-normal text-gray-600">
                            Useful for processing multiple documents or larger documents where each file or file/page/sheet is a separate entity.
                        </p>
                    </label>
                    <div className="mt-2 sm:mt-0 ">
                        <Dropdown
                            values={[
                                "All files together",
                                "Each file separately",
                                "Each section of pages separately",
                                "Each page/sheet separately"
                            ]}
                            ids={["all", "file", "section", "page"]}
                            selected={endpoint_unit_of_processing}
                            onChange={(unit) => { setIsInit(false); setEndpointUnitOfProcessing(unit as UnitOfProcessing) }} />
                    </div>
                </div>
                {endpoint_type === ENDPOINT_TYPE.rest_api && <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 py-6 border-t border-gray-900/10">
                    <label htmlFor="endpoint_rest_api_version" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>REST API version</p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Dropdown
                            values={["v1", "v2"]}
                            ids={["v1", "v2"]}
                            selected={endpoint_rest_api_version}
                            onChange={(version) => { setIsInit(false); setEndpointRestApiVersion(version as "v1" | "v2") }} />
                    </div>
                    <label htmlFor="endpoint_rest_api_check_api_key" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        <p>Check API key</p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <Checkbox
                            id="endpoint_rest_api_check_api_key"
                            checked={endpoint_rest_api_check_api_key}
                            setChecked={(new_value) => { setIsInit(false); setEndpointRestApiCheckApiKey(new_value) }}
                        />
                    </div>
                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                    Admin settings
                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 py-3">
                    <label htmlFor="endpoint_truncate_long_text" className="block sm:col-span-2 text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        <p>Truncate long text</p>
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <input
                            id="endpoint_truncate_long_text"
                            aria-describedby="endpoint_truncate_long_text"
                            name="endpoint_truncate_long_text"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={endpoint_truncate_long_text}
                            onChange={(e) => { setIsInit(false); setEndpointTruncateLongText(e.target.checked) }}
                        />
                    </div>
                </div>}

                {show_admin_details && is_admin && <div className="max-w-5xl">
                    <div className="py-6 text-sm font-bold leading-6 text-gray-400 border-t border-gray-200">
                        Raw JSON Settings (Admin Only)
                    </div>
                    <div className="w-full shadow border">
                        <CodeMirror
                            value={JSON.stringify({
                                uuid: endpoint_uuid,
                                type: endpoint_type as t.EndpointType,
                                name: endpoint_name,
                                org_uuid: endpoint_template?.org_uuid,
                                user_uuid: endpoint_user_uuid,
                                description: endpoint_desc,
                                ip_whitelist: endpoint_ip_whitelist,
                                details: {
                                    store_extractions: endpoint_store_extractions,
                                    require_confirmation: endpoint_require_confirmation,
                                    require_confirmation_users: endpoint_require_confirmation_users.map((email) => email.trim()).filter((email) => email !== ""),
                                    require_confirmation_notify_on_pending: endpoint_require_confirmation_notify_on_pending,
                                    join_object_extractions: endpoint_join_object_extractions,
                                    only_process_attachments: endpoint_only_process_attachments,
                                    unit_of_processing: endpoint_unit_of_processing,
                                    reply_to_sender: endpoint_reply_to_sender,
                                    reply_file_format: endpoint_reply_file_format,
                                    reply_file_extension: endpoint_reply_file_extension,
                                    reply_file_mimetype: endpoint_reply_file_mimetype,
                                    reply_include_input: endpoint_reply_include_input,
                                    forward_email_address: endpoint_forward_email_address === "CUSTOM" ? endpoint_forward_email_custom : endpoint_forward_email_address,
                                    forward_email_frequency: endpoint_forward_email_frequency,
                                    forward_email_digest_hour_utc: getUtcHour(endpoint_forward_email_digest_hour_local),
                                    forward_email_digest_group_field_uuid: endpoint_forward_email_digest_group_field_uuid,
                                    passthrough_email_address: endpoint_passthrough_email_address === "CUSTOM" ? endpoint_passthrough_email_custom : endpoint_passthrough_email_address,
                                    passthrough_email_subject: endpoint_passthrough_email_subject ?? [],
                                    webhook_url: endpoint_webhook_url,
                                    webhook_include_input: endpoint_webhook_include_input,
                                    webhook_version: endpoint_webhook_version,
                                    webhook_retry_count: endpoint_webhook_retry_count,
                                    webhook_store_payload: endpoint_webhook_store_payload,
                                    webhook_api_key: endpoint_webhook_api_key.length > 0 ? endpoint_webhook_api_key : undefined,
                                    webhook_fixed_headers: endpoint_webhook_fixed_headers,
                                    webhook_notify_on_fail_email_address: endpoint_webhook_notify_on_fail_email_address === "CUSTOM" ? endpoint_webhook_notify_on_fail_email_custom : endpoint_webhook_notify_on_fail_email_address,
                                    rest_api_version: endpoint_rest_api_version,
                                    rest_api_check_api_key: endpoint_rest_api_check_api_key,
                                    truncate_long_text: endpoint_truncate_long_text,
                                    handlebar_template: endpoint_handlebar_template
                                },
                                output_sheets: endpoint_output_sheets,
                                output_name: endpoint_output_name ?? [],
                                template_uuid: endpoint_template?.uuid
                            }, null, 2)}
                            height="400px"
                            theme="light"
                            extensions={[javascript()]}
                            readOnly={true}
                        />
                    </div>
                </div>}

                {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={hi.ChevronDownIcon} />
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={hi.ChevronUpIcon} />
                </div>}
            </form >
        </div >}
    </div >;
}

export function NewEndpointInit() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const wizard_new_email_roles = [USER_ROLES.admin, USER_ROLES.enterprise, USER_ROLES.professional, USER_ROLES.professional_plus];
    const wizard_new_rest_api_roles = [USER_ROLES.admin, USER_ROLES.enterprise];
    const wizard_new_lookup_table_roles = [USER_ROLES.admin, USER_ROLES.enterprise, USER_ROLES.professional_plus];

    // trail account
    const is_free = user.role === USER_ROLES.free;
    const personal_org = memberships.find((membership) => membership.org.type === ORG_TYPES.personal);
    const subscribe_url = personal_org ? `/stripe-subscribe/${personal_org.org.uuid}` : "/";

    const contactSales = () => {
        // redirect to home page
        window.location.href = "https://meetings-eu1.hubspot.com/tomaz/book-a-demo";
    };

    const selectWizard = (type: EndpointType, allowed_roles: string[]) => {
        if (!allowed_roles.includes(user.role as string)) {
            contactSales();
            return;
        }
        navigate(`/endpoint/new/${type}`);
    }

    const navigateWizard = (href: string, allowed_roles: string[]) => {
        if (!allowed_roles.includes(user.role as string)) {
            contactSales();
            return;
        }
        navigate(href);
    }

    return <div className={classNames("lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className={classNames("z-50 h-16 bg-white border-b-gray-200 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="px-10 py-4 lg:max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        Create new Integration
                    </h2>
                </div>
            </div>
        </div>

        <div className="p-6 mt-10 lg:pt-12">
            {is_free && <div className="mx-2 mt-2 p-2 text-lg bg-orange-50 border rounded-lg">
                <div className="px-5 py-2 flex flex-row items-center">
                    <p>This is a free trial account. Integrations are only available in our paid plans.</p>
                    <div className="flex-grow" />
                    <Button text="Upgrade" href={subscribe_url} />
                </div>
            </div>}

            <div className="px-6 py-8 text-gray-600">
                Select an integration template to get started.
            </div>
            <div className="pb-12 flex flex-wrap">
                <div className="opacity-100">
                    <WizardButtonIcon title="Email" icon={EnvelopeOpenIcon}
                        tooltip={!wizard_new_email_roles.includes(user.role) ? "Only available to our paying clients" : undefined}
                        locked={!wizard_new_email_roles.includes(user.role)}
                        onClick={() => selectWizard(ENDPOINT_TYPE.email, wizard_new_email_roles)} />
                </div>
                <div className="opacity-100">
                    <WizardButtonIcon title="Rest API" icon={Cog8ToothIcon}
                        tooltip={!wizard_new_rest_api_roles.includes(user.role) ? "Only available to our Enterprise clients" : undefined}
                        locked={!wizard_new_rest_api_roles.includes(user.role)}
                        onClick={() => selectWizard(ENDPOINT_TYPE.rest_api, wizard_new_rest_api_roles)} />
                </div>
                <div className="opacity-100">
                    <WizardButtonIcon title="Lookup Table" icon={hi.TableCellsIcon}
                        tooltip={!wizard_new_lookup_table_roles.includes(user.role) ? "Only available to our Professional+ and Enterprise clients" : undefined}
                        locked={!wizard_new_lookup_table_roles.includes(user.role)}
                        onClick={() => navigateWizard("/lookup_table/new", wizard_new_lookup_table_roles)} />
                </div>
            </div>
            <div className="flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardLogoButtonImage title="SAP S/4 HANA" image="/wizards/new_integration_sap_hana.png" onClick={contactSales} />
                </div>
                <div className="opacity-100">
                    <WizardLogoButtonImage title="Dynamics 365" image="/wizards/new_integration_microsoft_dynamics_365.png" onClick={contactSales} />
                </div>
                <div className="opacity-100">
                    <WizardLogoButtonImage title="NetSuite" image="/wizards/new_integration_oracle_netsuite.jpeg" onClick={contactSales} />
                </div>
                <div className="opacity-100">
                    <WizardLogoButtonImage title="Workday" image="/wizards/new_integration_workday.png" onClick={contactSales} />
                </div>
                <div className="opacity-100">
                    <WizardLogoButtonImage title="Xero" image="/wizards/new_integration_xero.png" onClick={contactSales} />
                </div>
                <div className="opacity-100">
                    <WizardLogoButtonImage title="QuickBooks" image="/wizards/new_integration_quickbooks.svg" onClick={contactSales} />
                </div>
                <div className="opacity-100">
                    <WizardLogoButtonImage title="SalesForce" image="/wizards/new_integration_salesforce.png" onClick={contactSales} />
                </div>
            </div>
        </div>
    </div>;
}
